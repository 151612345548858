/* ==========================================================================
 *	Theme 
 * ========================================================================== */
	:root
	{
		//- Type Faces 
		--type-font-sans: 'Helvetica Neue', 'Neue Helvetica', Helvetica, Arial, sans-serif;
		--type-font-serif: Georgia, 'Times New Roman', Times, serif;

		//- Colors
		--color-white: white;
		--color-light-gray: #f4f4f4;
		--color-medium-light-gray: #e6e6e6;
		--color-gray: #c8c8c8;
		--color-dark-gray: #595959;
		--color-darker-gray: #202020;
		--color-black: black;
		--color-brand-blue: #014b88;
		--color-brand-gray: #888888;
		--color-brand-light-blue: #3673b2;
		--color-brand-red: #e61920;
		--color-brand-light-red: #f4e3db;
		
		//- UI Color Assignments
			//- Document
			--ui-document-bg: var(--color-white);
			--ui-document-color: var(--color-black);
			--ui-document-mark-color: var(--color-white);
			--ui-document-mark-decoration-color: var(--color-brand-red);
		
			//- Components
				//- Errors
				--ui-error-color: var(--color-brand-red);
				
				//- Benefit Cards
				--ui-benefit-bg: var(--color-white);
				--ui-benefit-color: var(--color-black);
			
				//- Buttons
					//- Default
					--ui-button-bg: transparent;
					--ui-button-default-color: var(--color-brand-blue);
					--ui-button-disabled-color: var(--color-brand-gray);
					--ui-button-focus-color: var(--color-brand-light-blue);
					//- Inverted
					--ui-button-inverted-bg: var(--color-white);
					--ui-button-inverted-default-color: var(--color-brand-light-blue);
					--ui-button-inverted-disabled-color: var(--color-brand-gray);
					--ui-button-inverted-focus-color: var(--color-brand-red);

				//- Checkboxes
					--ui-checkbox-color: var(--color-dark-gray);
					//- Default
					--ui-checkbox-default-bg: var(--color-white);
					--ui-checkbox-default-border: var(--color-brand-blue);
					--ui-checkbox-default-color: var(--color-brand-blue);
					//- Focus & Hover
					--ui-checkbox-focus-bg: var(--ui-checkbox-default-bg);
					--ui-checkbox-focus-border: var(--color-brand-light-blue);
					--ui-checkbox-focus-color: var(--color-gray);
					//- Checked
					--ui-checkbox-checked-bg: var(--ui-checkbox-default-bg);
					--ui-checkbox-checked-border: var(--ui-checkbox-default-border);
					--ui-checkbox-checked-color: var(--ui-checkbox-default-color);
					//- Checked Focus & Hover
					--ui-checkbox-checked-focus-bg: var(--color-light-gray);
					--ui-checkbox-checked-focus-border: var(--ui-checkbox-default-border);
					--ui-checkbox-checked-focus-color: var(--ui-checkbox-default-color);
					//- Error
					--ui-checkbox-error-bg: var(--ui-checkbox-default-bg);
					--ui-checkbox-error-border: var(--ui-error-color);
					--ui-checkbox-error-color: var(--ui-error-color);
					//- Disabled
					--ui-checkbox-disabled-bg: var(--color-light-gray);
					--ui-checkbox-disabled-border: var(--color-gray);
					--ui-checkbox-disabled-color: var(--ui-checkbox-disabled-border);

				//- Checkcards
					--ui-checkcard-label-color: var(--color-brand-blue);
					--ui-checkcard-label-helper-color: var(--color-dark-gray);
					//- Default
					--ui-checkcard-default-bg: var(--color-white);
					--ui-checkcard-default-border: var(--color-brand-blue);
					--ui-checkcard-default-color: var(--color-brand-blue);
					--ui-checkcard-default-helper-color: var(--color-dark-gray);
					//- Focus & Hover
					--ui-checkcard-focus-bg: var(--color-medium-light-gray);
					--ui-checkcard-focus-border: var(--color-brand-light-blue);
					--ui-checkcard-focus-color: var(--color-brand-light-blue);
					--ui-checkcard-focus-helper-color: var(--color-dark-gray);
					//- Checked
					--ui-checkcard-checked-bg: var(--color-brand-blue);
					--ui-checkcard-checked-border: var(--color-brand-blue);
					--ui-checkcard-checked-color: var(--color-white);
					--ui-checkcard-checked-helper-color: var(--ui-checkcard-checked-color);
					//- Checked Focus & Hover
					--ui-checkcard-checked-focus-bg: var(--color-brand-light-blue);
					--ui-checkcard-checked-focus-border: var(--color-brand-light-blue);
					--ui-checkcard-checked-focus-color: var(--ui-checkcard-checked-color);
					--ui-checkcard-checked-focus-helper-color: var(--ui-checkcard-checked-helper-color);
					//- Error
					--ui-checkcard-error-bg: var(--ui-checkcard-default-bg);
					--ui-checkcard-error-border: var(--ui-error-color);
					--ui-checkcard-error-color: var(--ui-error-color);
					--ui-checkcard-error-helper-color: var(--ui-error-color);
					//- Disabled
					--ui-checkcard-disabled-bg: var(--color-light-gray);
					--ui-checkcard-disabled-border: var(--color-gray);
					--ui-checkcard-disabled-color: var(--ui-checkcard-disabled-border);
					--ui-checkcard-disabled-helper-color: var(--ui-checkcard-disabled-border);

				//- Dropdowns
					//- Default
					--ui-dropdown-default-bg: var(--color-light-gray);
					--ui-dropdown-default-border: var(--color-gray);
					--ui-dropdown-default-color: var(--color-dark-gray);
					--ui-dropdown-default-helper-color: var(--color-gray);
					--ui-dropdown-default-label-color: var(--color-dark-gray);
					//- Focus & Hover
					--ui-dropdown-focus-bg: var(--ui-dropdown-default-bg);
					--ui-dropdown-focus-border: var(--color-brand-blue);
					--ui-dropdown-focus-color: var(--ui-dropdown-default-color);
					--ui-dropdown-focus-helper-color: var(--ui-dropdown-default-helper-color);
					--ui-dropdown-focus-label-color: var(--ui-dropdown-focus-border);
					//- Error
					--ui-dropdown-error-bg: var(--ui-dropdown-default-bg);
					--ui-dropdown-error-border: var(--ui-error-color);
					--ui-dropdown-error-color: var(--ui-error-color);
					--ui-dropdown-error-helper-color: var(--ui-error-color);
					--ui-dropdown-error-label-color: var(--ui-error-color);
					//- Disabled
					--ui-dropdown-disabled-bg: var(--ui-dropdown-default-bg);
					--ui-dropdown-disabled-border: var(--ui-dropdown-default-border);
					--ui-dropdown-disabled-color: var(--ui-dropdown-default-color);
					--ui-dropdown-disabled-helper-color: var(--ui-dropdown-default-helper-color);
					--ui-dropdown-disabled-label-color: var(--ui-dropdown-default-label-color);
				
				//- Footnotes
					--ui-footnotes-color: var(--color-white);
					--ui-footnotes-counter-color: var(--color-gray);

				//- Radios
					--ui-radio-label-color: var(--color-brand-blue);
					//- Default
					--ui-radio-default-bg: var(--color-white);
					--ui-radio-default-border: var(--color-brand-blue);
					--ui-radio-default-color: var(--color-brand-blue);
					//- Focus & Hover
					--ui-radio-focus-bg: var(--color-medium-light-gray);
					--ui-radio-focus-border: var(--color-brand-light-blue);
					--ui-radio-focus-color: var(--color-brand-light-blue);
					//- Checked
					--ui-radio-checked-bg: var(--color-brand-blue);
					--ui-radio-checked-border: var(--color-brand-blue);
					--ui-radio-checked-color: var(--color-brand-blue);
					//- Error
					--ui-radio-error-bg: var(--ui-radio-default-bg);
					--ui-radio-error-border: var(--ui-error-color);
					--ui-radio-error-color: var(--ui-error-color);
					//- Disabled
					--ui-radio-disabled-bg: var(--color-light-gray);
					--ui-radio-disabled-border: var(--color-gray);
					--ui-radio-disabled-color: var(--ui-radio-disabled-border);

				//- radiocards
					--ui-radiocard-label-color: var(--color-brand-blue);
					--ui-radiocard-label-helper-color: var(--color-dark-gray);
					//- Default
					--ui-radiocard-default-bg: var(--color-white);
					--ui-radiocard-default-border: var(--color-brand-blue);
					--ui-radiocard-default-color: var(--color-brand-blue);
					--ui-radiocard-default-helper-color: var(--color-dark-gray);
					//- Focus & Hover
					--ui-radiocard-focus-bg: var(--color-medium-light-gray);
					--ui-radiocard-focus-border: var(--color-brand-light-blue);
					--ui-radiocard-focus-color: var(--color-brand-light-blue);
					--ui-radiocard-focus-helper-color: var(--color-dark-gray);
					//- Checked
					--ui-radiocard-checked-bg: var(--color-brand-blue);
					--ui-radiocard-checked-border: var(--color-brand-blue);
					--ui-radiocard-checked-color: var(--color-white);
					--ui-radiocard-checked-helper-color: var(--ui-radiocard-checked-color);
					//- Checked Focus & Hover
					--ui-radiocard-checked-focus-bg: var(--color-brand-light-blue);
					--ui-radiocard-checked-focus-border: var(--color-brand-light-blue);
					--ui-radiocard-checked-focus-color: var(--ui-radiocard-checked-color);
					--ui-radiocard-checked-focus-helper-color: var(--ui-radiocard-checked-helper-color);
					//- Error
					--ui-radiocard-error-bg: var(--ui-radiocard-default-bg);
					--ui-radiocard-error-border: var(--ui-error-color);
					--ui-radiocard-error-color: var(--ui-error-color);
					--ui-radiocard-error-helper-color: var(--ui-error-color);
					//- Disabled
					--ui-radiocard-disabled-bg: var(--color-light-gray);
					--ui-radiocard-disabled-border: var(--color-gray);
					--ui-radiocard-disabled-color: var(--ui-radiocard-disabled-border);
					--ui-radiocard-disabled-helper-color: var(--ui-radiocard-disabled-border);

				//- Textfields
					//- Default
					--ui-textfield-default-bg: var(--color-light-gray);
					--ui-textfield-default-border: var(--color-gray);
					--ui-textfield-default-color: var(--color-dark-gray);
					--ui-textfield-default-label-color: var(--color-dark-gray);
					--ui-textfield-default-helper-color: var(--ui-textfield-default-label-color);
					//- Focus & Hover
					--ui-textfield-focus-bg: var(--ui-textfield-default-bg);
					--ui-textfield-focus-border: var(--color-brand-blue);
					--ui-textfield-focus-color: var(--ui-textfield-default-color);
					--ui-textfield-focus-label-color: var(--ui-textfield-focus-border);
					//- Error
					--ui-textfield-error-bg: var(--ui-textfield-default-bg);
					--ui-textfield-error-border: var(--ui-error-color);
					--ui-textfield-error-color: var(--ui-error-color);
					--ui-textfield-error-label-color: var(--ui-error-color);
					//- Disabled
					--ui-textfield-disabled-bg: var(--ui-textfield-default-bg);
					--ui-textfield-disabled-border: var(--ui-textfield-default-border);
					--ui-textfield-disabled-color: var(--ui-textfield-default-color);
					--ui-textfield-disabled-label-color: var(--ui-textfield-default-label-color);
					//- (Currently) Unused color vars
					//- --ui-textfield-disabled-helper-color: var(--ui-textfield-default-helper-color);
					//- --ui-textfield-error-helper-color: var(--ui-textfield-default-helper-color);
					//- --ui-textfield-focus-helper-color: var(--ui-textfield-default-helper-color);
			
			//- Includes
				//- Cookie Content
				--include-cookie-legacy-bg: rgba(32,32,32,.98);
				--include-cookie-modern-bg: rgba(32,32,32,.85);
				--include-cookie-color: var(--color-white);
				--include-cookie-button-default-bg: var(--include-cookie-color);
				--include-cookie-button-default-color: var(--color-darker-gray);
				--include-cookie-button-focus-bg: var(--color-brand-light-blue);
				--include-cookie-button-focus-color: var(--color-white);
				//- Header
				--include-header-bg: var(--color-black);
				--include-header-color: var(--color-white);
				//- Navigation 
				--include-nav-color: var(--color-white);
				--include-nav-button-default-bg: transparent;
				--include-nav-button-default-border: var(--include-nav-color);
				--include-nav-button-default-color: var(--include-nav-color);
				--include-nav-button-focus-bg: var(--include-nav-color);
				--include-nav-button-focus-border: var(--include-nav-button-focus-bg);
				--include-nav-button-focus-color: var(--color-brand-light-blue);
				//- Post-Navigation
				--include-postnav-bg: var(--color-black);
				--include-postnav-border: var(--color-dark-gray);
				--include-postnav-color: var(--color-white);
				--include-postnav-focus-color: var(--color-brand-light-blue);
				//- Footer 
				--include-footer-bg: var(--color-black);
				--include-footer-color: var(--color-white);
			
			
			//- Regions
				//- How It Works
					--region-hiw-menu-color: var(--color-white);

		@media (prefers-color-scheme: dark)
		{

		}
	}