/* ==========================================================================
 *	 UI Elements, Forms
 *
 *	@section UI
 * ========================================================================== */
	@formUIClassname: ~'@{documentNamespace}-form';
	@formColUIClassname: ~'@{formUIClassname}__col';
	@formControlRowUIClassname: ~'@{formUIClassname}__controls';
	@formRowUIClassname: ~'@{formUIClassname}__row';
	@formPagingUIClassname: ~'@{formUIClassname}__paging';

	.@{formUIClassname}
	{
		-moz-osx-font-smoothing: grayscale !important;
		-ms-flex-negative: 0;
		-webkit-font-smoothing: subpixel-antialiased !important;
		align-items: flex-start;
		background-color: transparent;
		box-sizing: border-box;
		color: inherit;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
		font-size: 0.938rem;
		font-smooth: always !important;
		justify-content: flex-start;
		line-height: 1.800;
		margin: 0;
		max-width: 830px;
		min-height: 150px;
		overflow: hidden;
		padding: 0;
		text-align: left;
		text-rendering: optimizeLegibility !important;
		transition: background-color 1s ease, color 1s ease;
		width: 100%;
    position: relative;

		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		@media @big-breakpoints
		{
			font-size: 1rem;
			max-width: none;
		}

		.@{formUIClassname}__header
		{
			align-items: flex-start;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			margin-bottom: 30px;
			width: 100%;

			@media @big-breakpoints
			{
				margin-bottom: 80px;
				padding-right: 200px;
			}

			.@{formPagingUIClassname}
			{
				margin-bottom: 30px;
				order: 1;
				pointer-events: none;
				position: relative;
				text-align: right;

				@media @big-breakpoints
				{
					margin-bottom: 0;
					position: absolute;
					right: 0;
					top: 0;
				}

				&-page
				{
					box-sizing: border-box;
					color: var(--color-brand-light-blue);
					display: inline-block;
					font-size: 1.125rem;
					font-weight: bold;
					line-height: 1;
					padding-top: 10px;
					position: relative;
					transition: color .2s ease;

					@media (prefers-reduced-motion)
					{
						transition: none;
					}

					@media @big-breakpoints
					{
						font-size: 1.875rem;
						line-height: 1;
					}

					&:not(:first-child)
					{
						margin-left: 15px;

						@media @big-breakpoints
						{
							margin-left: 30px;
						}
					}

					&::before
					{
						border-top: var(--color-brand-red) solid 2px;
						content: '';
						height: 0;
						left: 50%;
						pointer-events: none;
						position: absolute;
						top: 0;
						transform: translateX(-50%);
						transition: width .2s ease;
						width: 0;

						@media (prefers-reduced-motion)
						{
							transition: none;
						}

						@media @big-breakpoints
						{
							border-top-width: 4px;
						}
					}

					&--active
					{
						color: var(--color-brand-blue);

						&::before
						{
							width: 100%;
						}
					}
				}
			}

			.@{headingUIClassname}
			{
				order: 2;

				@media @big-breakpoints
				{
					order: unset;
				}
			}

			hr
			{
				border: 0;
				border-top: dotted 0.125rem var(--color-gray);
				margin: 35px 0;
				width: 100%;
				order: 2;

				@media @big-breakpoints
				{
					border-top-width: 0.25rem;
					margin: 50px 0;
					order: unset;
					width: calc(100% + 200px);
				}
			}

			p
			{
				color: var(--color-brand-blue);
				font-size: 0.875rem;
				line-height: 1.125rem;
				order: 2;

				@media @big-breakpoints
				{
					font-size: 1.125rem;
					line-height: 1.750rem;
					order: unset;
				}

				a[href]
				{
					color: inherit;
					display: inline-block;
					font-weight: bold;
					position: relative;
					text-decoration: underline;
					transition: color .2s ease;

					@media (prefers-reduced-motion)
					{
						transition: none;
					}

					&:hover,
					&:focus,
					&:active
					{
						border: 0;
						outline: 0;
						text-decoration: none;
					}

					&:hover
					{
						color: var(--color-brand-light-blue);
					}

					&:focus:not(:hover)::before
					{
						background-color: var(--color-brand-light-red);
						border-radius: 3px;
						bottom: -3px;
						content: '';
						display: inline-block;
						left: -3px;
						opacity: 1;
						overflow: hidden;
						pointer-events: none;
						position: absolute;
						right: -3px;
						top: -3px;
						z-index: -1;
					}
				}
			}

			&-prereq
			{
				color: var(--color-brand-blue);
				margin: 0;
				order: 3;

				@media @big-breakpoints
				{
					order: 2;
				}

				sup
				{
					display: inline-block;
					font-size: 66%;
					transform: translateY(-0.25em);
					vertical-align: text-top;
				}
			}
		}

		&__content
		{
			max-width: 100%;
			width: 100%;

			.@{formRowUIClassname}
			{
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: stretch;
				width: 100%;

				@media @big-breakpoints
				{
					flex-direction: row;
					justify-content: space-between;
					margin-bottom: 30px;
				}
			}

			.@{formControlRowUIClassname}
			{
				flex-direction: row;
				justify-content: space-between;

				.@{buttonUIClassname}
				{
					&:only-child
					{
						@media @small-breakpoints
						{
							margin-left: auto;
							margin-right: auto;
						}

						@media @big-breakpoints
						{
							margin-left: auto;
						}
					}
				}
			}

			.@{formColUIClassname}
			{
				text-align: left;

				@media @small-breakpoints
				{
					clear: both;
					display: block;
					width: 100%;
				}

				@media @big-breakpoints
				{
					flex: 0 1 calc(33.3333333% - (50px / 2));

					&:last-child
					{
						flex-basis: calc(33.3333333% - (50px / 2));
					}

					&:first-child:nth-last-child(2)
					{
						flex-basis: calc(66.6666667% - (50px / 3));
					}

					&:only-child
					{
						flex-basis: 100%;
					}
				}
			}

			.@{formRowUIClassname}--swap-sizes
			{
				.@{formColUIClassname}
				{
					@media @big-breakpoints
					{
						&:last-child
						{
							flex-basis: calc(66.6666667% - (50px / 3));
						}

						&:first-child:nth-last-child(2)
						{
							flex-basis: calc(33.3333333% - (50px / 2));
						}
					}
				}
			}
		}

		&__privacy
		{
			flex: 0;
			font-size: 0.500rem;
			line-height: 0.625rem;
			margin-bottom: 35px;
			margin-top: 55px;
			max-width: 100%;

			@media @big-breakpoints,
			{
				flex: 0 1 auto;
				font-size: 0.750rem;
				line-height: 1.000rem;
				margin-top: 50px;
			}

			a[href]
			{
				color: inherit;
				text-decoration: underline;
				position: relative;
				transition: color .2s ease;

				@media (prefers-reduced-motion)
				{
					transition: none;
				}

				&:hover,
				&:focus,
				&:active
				{
					border: 0;
					outline: 0;
					text-decoration: none;
				}

				&:hover
				{
					color: var(--color-brand-light-blue);
				}

				&:focus:not(:hover)::before
				{
					background-color: var(--color-brand-light-red);
					border-radius: 3px;
					bottom: -3px;
					content: '';
					display: inline-block;
					left: -3px;
					opacity: 1;
					overflow: hidden;
					pointer-events: none;
					position: absolute;
					right: -3px;
					top: -3px;
					z-index: -1;
				}
			}
		}
	}