/* ==========================================================================
 *	 UI Elements, Checkboxes
 *
 *	@section UI
 * ========================================================================== */
	@checkboxUIClassname: ~'@{documentNamespace}-checkbox';
	@checkboxVisualUIClassname: ~'@{checkboxUIClassname}__visual';

	.@{checkboxUIClassname}
	{
		box-sizing: border-box;
		clear: both;
		color: var(--ui-checkbox-color);
		display: block;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.250rem;
		margin-bottom: 0;
		margin: 15px 0 45px;
		min-height: 18px;
		padding-left: 48px;
		position: relative;
		text-align: left;
		width: 100%;
		
		&__native
		{
			clear: both;
			cursor: pointer;
			display: inline-block;
			height: 100%;
			left: 0;
			margin: 0;
			opacity: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;

			@media @big-breakpoints
			{
				font-size: 1.125rem;
				line-height: 1.750rem;
			}
		}

		&__visual
		{
			background-color: var(--ui-checkbox-default-bg);
			border: solid 1px var(--ui-checkbox-default-border);
			box-sizing: border-box;
			cursor: pointer;
			display: inline-block;
			flex: 0 1 auto;
			font-size: 1rem;
			height: 18px;
			left: 0;
			overflow: hidden;
			pointer-events: none;
			position: absolute;
			top: 0;
			vertical-align: middle;
			width: 18px;
			z-index: 1;

			@media @big-breakpoints
			{
				border-width: 2px;
				height: 28px;
				width: 28px;
			}

			.@{checkboxUIClassname}__native:focus ~ &,
			.@{checkboxUIClassname}__native:hover ~ &
			{
				color: var(--ui-checkbox-focus-color);
			}

			.@{checkboxUIClassname}__native:checked ~ &
			{
				color: var(--ui-checkbox-checked-color);
			}

			.@{checkboxUIClassname}__native:checked:focus ~ &,
			.@{checkboxUIClassname}__native:checked:hover ~ &
			{
				background-color: var(--ui-checkbox-checked-focus-bg);
				color: var(--ui-checkbox-checked-focus-color);
			}
			
			[class*='icon'],
			&-indicator
			{
				color: inherit;
				display: none;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%,-50%);

				.@{checkboxUIClassname}__native:focus ~ &, 
				.@{checkboxUIClassname}__native:hover ~ &, 
				.@{checkboxUIClassname}__native:checked ~ &
				{
					display: block;
				}
			}
		}
		
		&--invalid .@{checkboxVisualUIClassname}
		{
			border-color: var(--ui-checkbox-error-border) !important;
		}
		
		.@{errorUIClassname}
		{
			margin-bottom: 0;
			margin-top: 0;
			padding-left: 0;
			padding-right: 0;

			@media @big-breakpoints
			{
				margin-top: -10px;
			}
		}
	}