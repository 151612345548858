//- Document Namespace and Root Classname
@documentNamespace: ~'usps';

//- Base Styles
@import '/src/styles/theme.less';
@import '/src/styles/document.less';
@import '/src/styles/typography.less';
@import '/src/styles/utilities.less';

//- Components Styles
@import '/src/components/brand/brand.less';
@import '/src/components/button/button.less';
@import '/src/components/checkbox/checkbox.less';
@import '/src/components/checkcard/checkcard.less';
@import '/src/components/dropdown/dropdown.less';
@import '/src/components/error/error.less';
@import '/src/components/footnotes/footnotes.less';
@import '/src/components/form/form.less';
@import '/src/components/heading/heading.less';
@import '/src/components/media/media.less';
@import '/src/components/radio/radio.less';
@import '/src/components/radiocard/radiocard.less';
@import '/src/components/reference/reference.less';
@import '/src/components/spinner/spinner.less';
@import '/src/components/textfield/textfield.less';

//- Includes Styles
@import '/src/includes/cookie-consent/cookie-consent.less';
@import '/src/includes/footer/footer.less';
@import '/src/includes/header/header.less';
@import '/src/includes/navigation/navigation.less';
@import '/src/includes/post-footnotes/post-footnotes.less';
@import '/src/includes/post-navigation/post-navigation.less';

//- Regions Styles
@import '/src/regions/benefits/benefits.less';
@import '/src/regions/contact/contact.less';
@import '/src/regions/how-it-works/how-it-works.less';

//- Generic View Styles
	//- Hero Sections
	@overviewHeroSectionId: ~'hero';

	#@{overviewHeroSectionId}
	{
		background-color: var(--color-black);
		background-position: 100% 67%;
		background-repeat: no-repeat;
		background-size: cover;
		color: var(--color-white);
		font-size: 0.875rem;
		line-height: 1.25rem;

		@media @big-breakpoints
		{
			background-position: 50% 100%;
			background-size: auto 100%;
			font-size: 1.375rem;
			line-height: 2.25rem;
			padding-top: 90px;
			padding-bottom: 90px;
		}
	}
	
	//- Intro Sections
	@overviewIntroSectionId: ~'intro';

	#@{overviewIntroSectionId}
	{
		background-color: var(--color-brand-blue);
		color: var(--color-white);
		font-size: 0.750rem;
		letter-spacing: 0.010rem;
		line-height: 1.250rem;
		padding-bottom: 120px;
		padding-top: 140px;
		text-align: center;
		transition: background-color 1s ease, color 1s ease;

		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		@media @big-breakpoints
		{
			font-size: 1.375rem;
			letter-spacing: -0.015rem;
			line-height: 2.250rem;
			padding: ~'110px calc(50vw - (672px / 2)) 140px';
		}
		
		p
		{
			margin: 0;

			b
			{
				clear: both;
				display: block;
				font-size: 1.625rem;
				font-weight: 700;
				line-height: 1.750rem;
				margin-bottom: 20px;

				@media @big-breakpoints
				{
					font-size: 1.750rem;
					line-height: 2.125rem;
					margin-bottom: 20px;
				}
			}
				
			sup:not([class])
			{
				display: inline-block;
				font-size: 50%;
				line-height: 0;
				position: relative;
				vertical-align: baseline;
				transform: translateY(-0.525em);
			}
			
			.@{referenceUIClassname}
			{
				transform: translateY(-6px);

				@media @big-breakpoints
				{
					transform: translateY(-9px);
				}
			}
		}
	}

//- Specific Views Styles
@import '/src/views/connect/connect.less';
@import '/src/views/home/home.less';
@import '/src/views/http-error/http-error.less';
@import '/src/views/local/local.less';
@import '/src/views/regional/regional.less';
@import '/src/views/national/national.less';
@import '/src/views/returns/returns.less';