/* ==========================================================================
 *	 UI Elements, errores
 *
 *	@section UI
 * ========================================================================== */
	@errorUIClassname: ~'@{documentNamespace}-error';

	.@{errorUIClassname}
	{
		box-sizing: border-box;
		clear: both;
		color: var(--ui-error-color);
		display: none;
		font-size: 0.813rem;
		line-height: 0.938rem;
		padding: 5px 0;
		text-align: left;
		width: 100%;
		margin-bottom: ~'calc(0.938rem * -1)';

		@media @big-breakpoints
		{
			font-size: 1rem;
			line-height: 1.125rem;
			margin-bottom: ~'calc((10px + 1.125rem) * -1)';
			padding: 5px 20px;
		}
		
		[class*='--invalid'] &
		{
			display: block;
		}
	}