/* ==========================================================================
 *	How It Works, Menu
 *
 *	@section Regions
 * ========================================================================== */
	.@{moduleClassname}__menu
	{
		align-items: flex-start;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		flex: 0 1 auto;
		justify-content: flex-start;
		margin-bottom: 20px;
		position: relative;
		width: 100%;

		@media @middle-breakpoints
		{
			margin-bottom: 0;
			margin-right: 25px;
			min-width: 250px;
			width: 250px;
		}

		@media @big-breakpoints
		{
			margin-bottom: 0;
			margin-right: 25px;
			min-width: 350px;
			width: 350px;
		}

		&::before
		{
			border-left: var(--region-hiw-menu-color) solid 2px;
			bottom: 0;
			content: '';
			display: inline-block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 0;
			opacity: 0.58;;

			@media @middle-breakpoints
			{
				border-width: 5px;
			}

			@media @big-breakpoints
			{
				border-width: 5px;
			}
		}

		&-item
		{
			color: var(--region-hiw-menu-color);
			font-size: 1.146rem;
			display: inline-block;
			line-height: 1.396rem;
			margin-bottom: 15px;
			opacity: 0.58;
			padding-left: 20px;
			position: relative;
			text-align: left;
			text-decoration: none;	
			transition: opacity .2s ease;

			[data-steps] &
			{
				align-items: flex-start;
				clear: both;
				display: inline-flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				padding-left: 62px;
			}
			
			@media (prefers-reduced-motion)
			{
				transition: none;
			}

			@media @middle-breakpoints
			{
				font-size: 1.250rem;
				line-height: 1.500rem;
				margin-bottom: 15px;
				
				[data-steps] &
				{
					padding-left: 71px;
				}
			}

			@media @big-breakpoints
			{
				font-size: 1.750rem;
				line-height: 2.000rem;
				margin-bottom: 20px;
				
				[data-steps] &
				{
					padding-left: 71px;
				}
			}
			
			&:last-of-type
			{
				margin-bottom: 0 !important;
			}

			&:hover,
			&:focus
			{
				opacity: 1;
			}

			&--active
			{
				font-weight: 700;
				opacity: 1;
			}
			
			&::before
			{
				border-left: var(--region-hiw-menu-color) solid 2px;
				bottom: 0;
				content: '';
				display: inline-block;
				height: 0;
				left: 0;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 0;

				@media @middle-breakpoints
				{
					border-width: 5px;
				}

				@media @big-breakpoints
				{
					border-width: 5px;
				}
			}

			&--active::before
			{
				height: 100%;
				transition: height .2s ease;
			
				@media (prefers-reduced-motion)
				{
					transition: none;
				}
			}
			
			&-n
			{
				font-weight: 400 !important;
				left: 17px;
				margin-right: 15px;
				position: absolute;
				speak: none;
				top: 0;

				@media @big-breakpoints
				{
					left: 21px;
				}

				@media @middle-breakpoints
				{
					left: 21px;
				}
			}
			
			sup
			{
				font-size: 66%;
				font-weight: normal;
				veritical-align: text-top;
			}
		}
	}