/* ==========================================================================
 *	UI Elements, References
 *
 *	@section UI
 * ========================================================================== */
 	@referenceUIClassname: ~'@{documentNamespace}-reference';

	.@{referenceUIClassname}
	{
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background: transparent;
		border-radius: 0;
		border: 0;
		box-shadow: none;
		color: inherit;
		display: inline-block;
		font-family: inherit;
		font-size: 50%;
		height: 1em;
		line-height: 0;
		overflow: visible;
		padding: 0;
		position: relative;
		transform: translateY(-.525em);
		vertical-align: baseline;

		&[role='link']:focus,
		&[role='link']:hover
		{
			cursor: pointer;
			text-decoration: underline;
		}
	}