/* ==========================================================================
 *	Home Page
 *
 *	@section Views
 * ========================================================================== */
	@homeViewId: ~'home';

/* ==========================================================================
 *	Home Page, Overview Section
 *
 *	@section Views
 * ========================================================================== */
	#@{homeViewId}
	{
		@overviewSectionId: ~'overview';
		
		#@{overviewSectionId}
		{
			min-height: 0;
		}
	}

/* ==========================================================================
 *	Home Page, Overview Section, Hero
 *
 *	@section Views
 * ========================================================================== */
	#@{overviewHeroSectionId}
	{
		background-image: linear-gradient(90deg, rgba(0,0,0,.2) 35%, rgba(0,0,0,0) 70%),url('/src/views/home/media/hero.bg.ls.jpg');
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		font-size: 0.875rem;
		line-height: 1.25rem;
		min-height: 80vh;

		@media (orientation: portrait)
		{
			background-image: url('/src/views/home/media/hero.bg.pt.jpg');
			background-position: 50%;
		}

		@media @middle-breakpoints
		{
			font-size: 1.375rem;
			line-height: 2.25rem;
			min-height: 0;
			padding-bottom: 200px;
			padding-top: 200px;
		}

		@media @big-breakpoints
		{
			font-size: 1.375rem;
			line-height: 2.25rem;
			min-height: 0;
			padding-bottom: 240px;
			padding-top: 240px;
		}

		//- Child Section
		@overviewIntroSectionId: ~'intro';

		#@{overviewIntroSectionId}
		{
			background-color: var(--color-brand-blue);
			color: var(--color-white);
			font-size: 0.750rem;
			letter-spacing: 0.010rem;
			line-height: 1.250rem;
			margin-bottom: 0;
			padding-bottom: 120px;
			padding-top: 140px;
			text-align: center;
			transition: background-color 1s ease, color 1s ease;

			@media (prefers-reduced-motion)
			{
				transition: none;
			}

			@media @big-breakpoints
			{
				font-size: 1.375rem;
				letter-spacing: -0.015rem;
				line-height: 2.250rem;
				padding: ~'110px calc(50vw - (672px / 2)) 140px';
			}
			
			p
			{
				margin: 0;

				b
				{
					clear: both;
					display: block;
					font-size: 1.625rem;
					font-weight: 700;
					line-height: 1.750rem;
					margin-bottom: 20px;

					@media @middle-breakpoints
					{
						font-size: 1.750rem;
						line-height: 2.125rem;
						margin-bottom: 20px;
					}

					@media @big-breakpoints
					{
						font-size: 1.750rem;
						line-height: 2.125rem;
						margin-bottom: 20px;
					}
				}
				
				.@{referenceUIClassname}
				{
					transform: translateY(-6px);

					@media @big-breakpoints
					{
						transform: translateY(-9px);
					}
				}
			}
		}
			
		.@{documentNamespace}-heading
		{
			font-size: 1.791rem;
			line-height: 1.791rem;
			letter-spacing: 0.01em;

			@media @middle-breakpoints
			{
				font-size: 2.813rem;
				line-height: 2.813rem;
				letter-spacing: 0;
			}

			@media @big-breakpoints
			{
				font-size: 2.813rem;
				line-height: 2.813rem;
				letter-spacing: 0;
			}
		}
		
		sup:not([class])
		{
			display: inline-block;
			font-size: x-small;
			line-height: 0;
				transform: translateY(-.30em);
			vertical-align: baseline;

			@media @middle-breakpoints
			{
				transform: translateY(-.625em);
			}

			@media @big-breakpoints
			{
				transform: translateY(-.85em);
			}
		}
		
		.@{documentRegionContentClassname}
		{
			@media @small-breakpoints
			{
				margin-top: 40vmin;
			}
		}
	}
	
/* ==========================================================================
 *	Home Page, Explore Section
 *
 *	@section Views
 * ========================================================================== */
	#@{homeViewId}
	{
		@exploreSectionId: ~'explore';

		#@{exploreSectionId}
		{
			background-color: var(--color-brand-blue);
			color: var(--color-white);
			font-size: 0.750rem;
			letter-spacing: 0.010rem;
			line-height: 1.250rem;
			text-align: center;
			padding-top: 140px;
			padding-bottom: 120px;
			transition: background-color 1s ease, color 1s ease;

			@media (prefers-reduced-motion)
			{
				transition: none;
			}

			@media @big-breakpoints
			{
				font-size: 1.375rem;
				letter-spacing: -0.015rem;
				line-height: 2.250rem;
				padding-bottom: 175px;
				padding-top: 275px;
			}
			
			.@{headingUIClassname}
			{
				margin-bottom: 40px;
				text-align: left;

				@media @middle-breakpoints
				{
					margin-bottom: 100px;
				}

				@media @big-breakpoints
				{
					margin-bottom: 200px;
					text-align: center;
				}
			}
		}
	}
	
/* ==========================================================================
 *	Home Page, Explore Section, Product List
 *
 *	@section Views
 * ========================================================================== */
	@exploreSectionProductsClassname: ~'@{documentNamespace}-products';

	.@{exploreSectionProductsClassname}
	{
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;

		@media @big-breakpoints
		{
			flex-direction: row;
		}
		
		.@{buttonUIClassname}
		{
			margin: 30px auto 0;
			max-width: 204px;

			[data-dynamic]
			{
				text-transform: capitalize;
			}
		}

		&__menu
		{
			align-items: flex-start;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			flex: 1;
			justify-content: flex-start;
			margin-bottom: 40px;
			width: 100%;
			
			@media @small-breakpoints
			{
				gap: 15px;
			}

			@media @big-breakpoints
			{
				flex-direction: column;
				flex-wrap: nowrap;
				flex: 0 1 34%;
				margin-bottom: 0;
				width: 34%;
			}
			
			.@{buttonUIClassname}
			{
				@media @big-breakpoints
				{
					margin: 30px 30px;
					max-width: none;
					min-width: 204px;
				}
				
				[data-dynamic]
				{
					text-transform: capitalize;
				}
			}
			
			&-item
			{
				background: transparent;
				border-radius: 0;
				border: 0;
				box-shadow: none;
				box-sizing: border-box;
				clear: both;
				color: inherit;
				cursor: pointer;
				display: block;
				font-family: inherit;
				font-size: 1.458rem;
				line-height: 2.098rem;
				opacity: .5;
				padding-bottom: 10px;
				position: relative;
				text-decoration: none;
				transition: opacity .2s ease;

				@media (prefers-reduced-motion)
				{
					transition: none;
				}
				
				@media @small-breakpoints
				{
					align-items: center;
					border-radius: 2px;
					border: var(--color-white) solid 1px;
					display: inline-flex;
					font-size: unset;
					font-weight: bold;
					justify-content: center;
					opacity: 1;
					padding: 1px 10px 0;
				}

				@media @big-breakpoints
				{
					font-size: 4.063rem;
					line-height: 1;
					margin-bottom: 15px;
					padding-bottom: 0;
					padding-left: 30px;
					text-align: left;
					width: 100%;
				}

				@media (min-width: 960px) and (max-width: @big-breakpoint-fat-snap)
				{
					font-size: 2.813rem;
					line-height: 1;
					padding-left: 25px;
				}

				&:hover,
				&:focus
				{
					opacity: 1;
				}
				
				&::before
				{
					content: '';
					height: 0;
					left: 0;
					opacity: 0;
					pointer-events: none;
					position: absolute;
					width: 0;

					@media @small-breakpoints
					{
						display: none;
					}

					@media @big-breakpoints
					{
						border-right: var(--color-brand-red) solid 4px;
						top: calc(50% + 1.625px);
						transform: translateY(-50%);
						transition: opacity .2s ease, height .3s ease;
						width: 0;
						
						@media (prefers-reduced-motion)
						{
							transition: none;
						}
					}
				}

				&--selected
				{
					font-weight: bold;
					opacity: 1;

					@media @small-breakpoints
					{
						background: var(--color-white);
						color: var(--color-brand-blue);
					}

					&::before
					{
						opacity: 1;

						@media @big-breakpoints
						{
							height: 71%;
						}
					}
				}
			}
		}
		
		&__features
		{
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			
			@media @middle-breakpoints
			{
				flex-direction: row;
				flex: 0 1 100%;
				width: 100%;
			}
			
			@media @big-breakpoints
			{
				flex-direction: row;
				flex: 0 1 66%;
				width: 66%;
			}

			&-list
			{
				box-sizing: border-box;
				clear: both;
				list-style: none;
				margin: 0;
				padding: 0;
				text-align: left;
				width: 100%;

				@media @middle-breakpoints
				{
					&:first-child,
					&:first-of-type
					{
						padding-right: 35px;
						text-align: left;
						width: 55%;
					}
				}

				@media @big-breakpoints
				{
					&:first-child,
					&:first-of-type
					{
						padding-right: 35px;
						text-align: left;
						width: 55%;
					}
				}
				
				@media (min-width: 960px) and (max-width: @big-breakpoint-fat-snap)
				{
					&:first-child,
					&:first-of-type
					{
						padding-right: 20px;
					}
				}

				&item
				{
					clear: both;
					display: block;
					font-size: 0.875rem;
					line-height: 1.146rem;
					list-style: none;
					margin: 0 0 20px;
					opacity: .5;
					padding: 0;
					pointer-events: none;
					transition: opacity .2s ease;
					white-space: nowrap;
					width: 100%;
						
					@media (prefers-reduced-motion)
					{
						transition: none;
					}
			
					@media @middle-breakpoints
					{
						font-size: 1.250rem;
						line-height: 1.50rem;
						margin-bottom: 31px;
					}
			
					@media @big-breakpoints
					{
						font-size: 1.750rem;
						line-height: 2.125rem;
						margin-bottom: 42px;
					}

					@media (min-width: 960px) and (max-width: @big-breakpoint-fat-snap)
					{
						font-size: 1.375rem;
						line-height: 1.750rem;
						margin-bottom: 20px;
					}

					&--supported
					{
						font-weight: bold;
						opacity: 1;
						pointer-events: auto;
					}
				}
			}
		}
	}

/* ==========================================================================
 *	Home Page, Products Section
 *
 *	@section Views
 * ========================================================================== */
	#@{homeViewId}
	{
		@productsSectionId: ~'products';
		@productsClassName: ~'@{documentNamespace}-products';
		@productsCardClassName: ~'@{documentNamespace}-products__card';

		#@{productsSectionId}
		{
			background-color: var(--color-black);
			background-image: url('/src/views/home/media/products.bg.jpg');
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: auto 125%;
			color: var(--color-white);
			font-size: 0.750rem;
			letter-spacing: 0.010rem;
			line-height: 1.250rem;
			padding-bottom: 20px;
			padding-top: 0;	
			text-align: center;
			transition: background-color 1s ease, background-size .2s ease, color 1s ease;

			@media (prefers-reduced-motion)
			{
				transition: none;
			}

			@media @small-breakpoints
			{
				background-size: auto 125% !important;
		  	background-image: url('/src/views/home/media/products.bg.sm.jpg');
			}

			@media @big-breakpoints
			{
				background-size: cover;
			}
			
			@supports (background-attachment: fixed)
			{
				background-attachment: fixed;
			}
			
			.@{productsClassName}
			{
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				font-size: 0.750rem;
				justify-content: flex-start;
				letter-spacing: 0.01rem;
				line-height: 1.091rem;
				margin-bottom: 200px;
				opacity: 1;

				@media @middle-breakpoints
				{
					align-items: stretch;
					flex-direction: row;
					flex-wrap: wrap;
					font-size: 1rem;
					justify-content: space-between;
					line-height: 1.175rem;
				}

				@media @big-breakpoints
				{
					align-items: stretch;
					flex-direction: row;
					flex-wrap: wrap;
					font-size: 1rem;
					justify-content: space-between;
					line-height: 1.313rem;
					margin-bottom: 165px;
				}
				
				.@{productsCardClassName}
				{
					align-items: flex-start;
					background: var(--ui-benefit-bg);
					border-radius: 6px;
					box-shadow: none;
					box-sizing: border-box;
					color: var(--ui-benefit-color);
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;
					flex: 0 1 auto;
					justify-content: flex-start;
					margin-bottom: 35px;
					outline: 0;
					padding: 15px 35px;
					position: relative;
					text-align: left;
					text-decoration: none;
					width: 100%;

					@media @middle-breakpoints
					{
						flex-basis: calc(25% - ((30px * 2) / 3));
						margin-bottom: 25px;
						padding: 20px 30px;
						width: calc(25% - ((30px * 2) / 3));
					}

					@media @big-breakpoints
					{
						flex-basis: calc(25% - ((30px * 2) / 3));
						margin-bottom: 25px;
						padding: 20px 30px;
						width: calc(25% - ((30px * 2) / 3));
					}
					
					&-heading
					{
						clear: both;
						display: block;
						font-size: 0.875rem;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 1.042rem;
						margin-bottom: 10px;
						pointer-events: none;

						@media @big-breakpoints
						{
							font-size: 1.125rem;
							line-height: 2.25rem;
						}
						
						mark
						{
							background: transparent;
							color: inherit;
							font-size: 1.750rem;
							font-weight: 800;
							line-height: 1.750rem;

							@media @big-breakpoints
							{
								font-size: 2.625rem;
								line-height: 2.813rem;
							}
						}
			
						sup:not([class])
						{
              display: inline-block;
              font-size: 50%;
              transform: translateY(-.45em);
              vertical-align: top;
						}

						.@{referenceUIClassname}
						{
							font-size: 75%;
							font-weight: bold;
							vertical-align: text-top;
							transform: translateY(-0.3em);
						}
					}
					
					&-description
					{
						margin-bottom: auto;

						.@{referenceUIClassname}
						{
							&:focus:not(:hover)
							{
								color: var(--color-white);
							}

							&:focus:not(:hover)::before
							{
								background-color: var(--color-brand-light-blue);
								border-radius: 3px;
								bottom: -3px;
								color: inherit;
								content: '';
								display: inline-block;
								left: -3px;
								opacity: 1;
								overflow: hidden;
								pointer-events: none;
								position: absolute;
								right: -3px;
								top: -3px;
								z-index: -1;
							}
						}
					}
					
					&-cta
					{
						background-color: var(--color-white);
						border-radius: 100vmin;
						border: var(--color-brand-blue) solid 1px;
						box-sizing: border-box;
						height: 36px;
						margin-top: 25px;
						position: relative;
						transition: border-color .2s ease, background-color .2s ease;
						width: 36px;

						@media (prefers-reduced-motion)
						{
							transition: none;
						}

						@media @big-breakpoints
						{
							margin-top: 25px;
						}

						svg
						{
							height: 45%;
							left: 50%;
							margin: 0;
							position: absolute;
							top: 50%;
							transform: translate(-50%,-50%);
							transition: height .2s ease;

							@media (prefers-reduced-motion)
							{
								transition: none;
							}
							
							path
							{
								fill: var(--color-brand-blue);
								transition: fill .2s ease;

								@media (prefers-reduced-motion)
								{
									transition: none;
								}
							}
						}
					}
					
					&-graphic
					{
						display: block;
						margin: 20px auto 0;
						max-width: 110px;
						pointer-events: none;
						width: 100%;
					}

					.@{productsClassName}__card-cta
					{
						&:hover,
						&:focus
						{
							background-color: var(--color-brand-light-blue);
							border-color: var(--color-brand-light-blue);
							
							svg
							{
								height: 45%;

								path
								{
									fill: var(--color-white);
								}
							}
						}
					}
				}
			}
			
			.@{documentNamespace}-graphic-text
			{
				clear: both;
				display: block;
				font-size: 2.75rem;
				font-weight: normal; 
				letter-spacing: -0.01em;
				line-height: 2.75rem;
				margin-bottom: 50px;
				margin-top: 60px;
				text-align: left;

				@media @big-breakpoints
				{
					font-size: 4.75rem;
					letter-spacing: 0.025em;
					line-height: 4.75rem;
					margin-bottom: 200px;
					margin-top: 150px;
				}
				
				mark
				{
					background-color: transparent;
					background-image: linear-gradient(180deg, transparent 92.50%, var(--ui-document-mark-decoration-color) 0);
					background-repeat: no-repeat;
					background-size: 100% 100%;
					color: var(--ui-document-mark-color);
					display: inline;
					font-weight: bold;
					position: relative;
					transition: color 1s ease;

					@media (prefers-reduced-motion)
					{
						transition: none;
					}
				}
				
				.@{referenceUIClassname}
				{
					font-size: 25%;
					transform: translateY(-1.35em);

					@media @big-breakpoints
					{
						font-size: 25%;
					}
				}
			}
		}
	}
	
/* ==========================================================================
 *	Home Page, Video Section
 *
 *	@section Views
 * ========================================================================== */
	#@{homeViewId}
	{
		@watchSectionId: ~'watch';

		#@{watchSectionId}
		{
			@media @small-breakpoints
			{
				min-height: 0;
				padding-left: 0;
				padding-right: 0;
			}
		
			.@{headingUIClassname}
			{
				text-align: left;

				@media @big-breakpoints
				{
					margin-top: 150px;
					text-align: center;
				}
			}

			.@{documentNamespace}-media
			{
				margin: 15px 0 -15px;
				max-width: 100%;
				padding-bottom: 0;
				position: relative;
				width: 100%;

				@media @big-breakpoints
				{
					margin-top: 150px;
				}
				
				&__video
				{
					margin: 0;
					max-width: 100%;
					overflow: hidden;
					position: relative;
					width: 100%;
					z-index: 1;

					@media @big-breakpoints
					{
						border-radius: 12px;
					}
					
					&[inert],
					&[tabindex='-1']
					{
						pointer-events: none;
					}
				}
				
				&__poster
				{
					background-color: transparent;
					background-position: 50% 50%;
					background-repeat: no-repeat;
					background-size: cover;
					border: 0;
					bottom: 0;
					box-shadow: var(--color-brand-light-blue) 0 0 0 0 inset;
					box-sizing: border-box;
					cursor: pointer;
					display: block;
					height: 100%;
					left: 0;
					margin: 0;
					opacity: 1;
					outline: 0;
					padding: 0;
					position: absolute;
					right: 0;
					top: 0;
					transition: box-shadow .2s ease;
					width: 100%;
					z-index: 2;

					@media (prefers-reduced-motion)
					{
						transition: none;
					}

					@media @big-breakpoints
					{
						border-radius: 12px;
					}

					&:hover,
					&:focus,
					&:active
					{
						box-shadow: var(--color-brand-light-blue) 0 0 0 6px inset;
					}

					&:active
					{
						box-shadow: var(--color-brand-blue) 0 0 0 6px inset;
					}
					
					&[hidden]
					{
						display: none;
					}
					
					&-label
					{
						color: var(--color-white);
						display: block;
						font-size: 0.87rem;
						font-weight: bold;
						left: 0;
						line-height: 1.375rem;
						pointer-events: none;
						position: absolute;
						text-align: center;
						text-shadow: rgba(0,0,0,.5) 0 0 2px;
						top: 50%;
						width: 100%;

						@media @big-breakpoints
						{
							font-size: 1.625rem;
							line-height: 2.000rem;
						}
						
						&-icon
						{
							clear: both;
							display: block;
							margin: -28.5px auto 15px;
							pointer-events: none;
							width: 42px;

							@media @big-breakpoints
							{
								margin-top: -38.5px;
								margin-bottom: 25px;
								width: 52px;
							}
						}
					}
				}
			}
		}
	}