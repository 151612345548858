/* ==========================================================================
 *	Navigation Mobile Trigger
 *
 *	@section Landmarks
 * ========================================================================== */
	@navTriggerLandmarkClassname: ~'@{navLandmarkClassname}__trigger';

	.@{navTriggerLandmarkClassname}
	{
		@size: 48px;

		-moz-appearance: none;
		-ms-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background: transparent;
		border: 0;
		box-shadow: none;
		cursor: pointer;
		display: block;
		height: @size;
		margin: 0 0 0 auto;
		outline: 0;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		right: calc(@small-breakpoints-gutter / 2);
		top: 6px;
		width: @size;
		z-index: 11;
		
		& + [class*='icon']
		{
			@size: 48px;

			background: transparent;
			border: 0;
			box-shadow: none;
			display: block;
			height: @size;
			margin: 0 0 0 auto;
			outline: 0;
			overflow: hidden;
			pointer-events: none;
			position: absolute;
			right: calc(@small-breakpoints-gutter / 2);
			top: 6px;
			transition: filter .2s ease;
			width: @size;
			z-index: 10;
		}
	}