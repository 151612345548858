/* ==========================================================================
 *	How It Works, Content Display
 *
 *	@section Regions
 * ========================================================================== */
	.@{moduleClassname}__content
	{
		--padding-block: 20px;
		--padding-inline: 15px;
	
		align-items: center;
		border: var(--color-white) solid 2px;
		box-sizing: border-box;
		display: block;
		flex: 1 1 auto;
		font-size: 1rem;
		justify-content: center;
		line-height: 1.25rem;
		min-height: calc((1.042rem * 5) + (20px * 2));
		padding: var(--padding-block) var(--padding-inline);
		position: relative;
		width: 100%;

		@media @middle-breakpoints
		{
			--padding-block: 25px;
			--padding-inline: 30px;

			border-width: 3px;
			font-size: 1rem;
			line-height: 1.25rem;
			min-height: calc((1.042rem * 5) + (20px * 2));
			width: calc(100% - 250px);
		}

		@media @big-breakpoints
		{
			--padding-block: 50px;
			--padding-inline: 60px;

			border-width: 3px;
			font-size: 1.375rem;
			line-height: 1.875rem;
			min-height: calc((1.875rem * 5) + (50px * 2));
			width: calc(100% - 350px);
		}

		.@{moduleClassname}__header
		{
			align-items: center;
			border-bottom: var(--color-white) solid 2px;
			clear: both;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			height: 200px;
			justify-content: center;
			margin: calc(var(--padding-block) * -1) calc(var(--padding-inline) * -1) var(--padding-block);
			width: calc(100% * (var(--padding-inline) * 2));

			@media @middle-breakpoints
			{
				border-width: 3px;
				height: 275px;
			}

			@media @big-breakpoints
			{
				border-width: 3px;
				height: 300px;
			}

			[data-steps] &
			{
				height: 100px;

				@media @middle-breakpoints
				{
					height: 175px;
				}

				@media @big-breakpoints
				{
					height: 200px;
				}
			}

			.@{moduleClassname}__media
			{
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				-webkit-tap-highlight-color: rgba(0,0,0,0);
				-webkit-tap-highlight-color: transparent;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				align-items: center;
				box-sizing: border-box;
				display: flex;
				flex: 0 1 auto;
				height: 100%;
				justify-content: center;
				overflow: hidden;
				padding: calc(var(--padding-inline) / 2) 0;
				position: relative;
				text-align: center;
				vertical-align: middle;
				width: 100%;

				audio, embed, img, object, picture, svg, video
				{
					image-rendering: -webkit-optimize-contrast;
					image-rendering: crisp-edges;
					image-rendering: high-quality;
					margin: 0;
					max-width: 100%;
					width: 100%;
				}
				
				/* lottie-player
				{
					svg
					{
						path
						{
							fill-opacity: 0;
							stroke-dasharray: 1;
							stroke-linecap: round;
							stroke-linejoin: miter;
							stroke-miterlimit: 4;
							stroke-opacity: 1;
							stroke-width: 3.5;
							stroke: var(--color-white);
						}
					}
				} */

				[data-steps] &
				{
					border-left: var(--color-white) solid 2px;
					padding: calc(var(--padding-inline) / 2);
					width: 57.86%;

					@media @middle-breakpoints
					{
						border-width: 3px;
					}

					@media @big-breakpoints
					{
						border-width: 3px;
					}
				}
			}
			
			.@{moduleClassname}__indicator
			{
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				-webkit-tap-highlight-color: rgba(0,0,0,0);
				-webkit-tap-highlight-color: transparent;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				align-items: center;
				display: inline-flex;
				flex: 0 1 auto;
				font-size: 5.525rem;
				font-weight: 700;
				height: 100%;
				justify-content: center;
				line-height: 5.525rem;
				min-height: 100%;
				overflow: hidden;
				padding: 0;
				position: relative;
				text-align: center;
				vertical-align: middle;
				width: calc(42.14% - 2px);

				@media @very-small-breakpoints
				{
					font-size: 3rem;
					line-height: 3rem;
				}

				@media @middle-breakpoints
				{
					font-size: 5rem;
					line-height: 6rem;
					width: calc(42.14% - 3px);
				}

				@media @big-breakpoints
				{
					font-size: 7.813rem;
					line-height: 7.813rem;
					width: calc(42.14% - 3px);
				}
				
				&::before
				{
					content: '0';
					transform: translateX(-50%);
					speak: none;
				}

				&-n
				{
					font-size: inherit;
					font-weight: inherit;
					display: block;
					left: 50%;
					line-height: inherit;
					margin: 0;
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);

					&[aria-hidden]
					{
						display: none;
					}
				}
			}
		}

		&-roll
		{
			display: block;
			height: auto;
			overflow: hidden;
			position: relative;
			text-align: left;
			transition: height .25s ease;
			width: 100%;
			
			@media (prefers-reduced-motion)
			{
				transition: none;
			}

			p
			{
				bottom: 0;
				left: 0;
				opacity: 1;
				position: absolute;
				transition: opacity .35s ease .5s;
				width: 100%;
				z-index: 0;
			
				@media (prefers-reduced-motion)
				{
					transition: none;
				}

				&[aria-hidden]
				{
					opacity: 0;
					pointer-events: none;
					transition-delay: 0s !important;
					z-index: 0;
				}
				
				sup
				{
					display: inline-block;
					font-size: 50%;
					transform: translateY(-.45em);
					vertical-align: top;
				}
			}
		}
	}