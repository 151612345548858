/* ==========================================================================
 *	Contact
 *
 *	@section Regions
 * ========================================================================== */
	@contactRegionId: ~'contact';

	.@{documentRegionClassname}#@{contactRegionId}
	{
		overflow: hidden;

		.@{documentRegionClassname}
		{
			&__content,
			&__standalone-content
			{
				padding-bottom: 15px;
				padding-top: 15px;

				&:not[hidden]
				{
					display: block;
				}

				@media @big-breakpoints
				{
					padding-bottom: 50px;
					padding-top: 50px;
				}
			}
		}

		//- Intros
		#@{contactRegionId}-intro
		{
			font-size: 1.458rem;
			line-height: 1.771rem;
			padding-bottom: 120px;
			padding-top:	120px;
			text-align: left;

			@media @big-breakpoints
			{
				font-size: 1.375rem;
				line-height: 1.875rem;
				padding-bottom: 100px;
				padding-top:	132px;
			}
			
			.@{headingUIClassname}
			{
				margin-bottom: 15px;
				text-align: left;

				@media @big-breakpoints
				{
					text-align: center;
				}
			}

			.@{gridRowClassname}
			{
				color: var(--color-black);
				text-align: left;

				.@{buttonUIClassname}
				{
					@media @small-breakpoints
					{
						margin-top: 20px;
					}

					@media @big-breakpoints
					{
						flex: 0;
						margin-left: 10px;
					}
				}
			}
		}

		//- Contact Form
		#@{contactRegionId}-form
		{
			color: var(--color-brand-blue);
			overflow: hidden;

			.@{formUIClassname}__content
			{
				&#business-information
				{
					@media @big-breakpoints
					{
						.@{formRowUIClassname}:nth-child(2)
						{
							.@{formColUIClassname}:first-child
							{
								flex-basis: calc(33.3333333% - (50px / 2));
							}
							
							.@{formColUIClassname}:last-child
							{
								flex-basis: calc(66.6666666% - (50px / 2));
								margin-left: 50px;
							}
						}
					}
				}
			}

			.@{buttonUIClassname}
			{
				&:only-child
				{
					margin-left: auto;
				}
			
				&:not(.@{buttonUIClassname}--outline)
				{
					background-color: var(--ui-button-default-color);
					border-color: var(--ui-button-default-color);
					color: var(--color-white);
					
					&:hover,
					&:focus,
					&:active
					{
						background-color: var(--color-brand-light-blue);
						border-color: var(--color-brand-light-blue);
					}
				}
			}
			
			.@{formUIClassname}__privacy
			{
				color: var(--color-black);
			}
		}

		//- Status Views
		#@{contactRegionId}-loading,
		#@{contactRegionId}-error,
		#@{contactRegionId}-thankyou
		{
			font-size: 1.458rem;
			line-height: 1.771rem;
			padding-bottom: 120px;
			padding-top:	120px;
			text-align: center;
			width: 100%;
			
			.@{spinnerUIClassname}
			{
				margin: -10px auto 0;
			}

			@media @big-breakpoints
			{
				font-size: 1.375rem;
				line-height: 1.875rem;
				padding-top: 132px;
				padding-bottom: 100px;
			
				.@{spinnerUIClassname}
				{
					margin-top: -20px;
				}
			}
		}

		#@{contactRegionId}-loading,
		#@{contactRegionId}-error,
		{
			color: var(--color-brand-blue);
		}
	}