/* ==========================================================================
 *	UI Elements, Media Objects
 *
 *	@section UI
 * ========================================================================== */
	@mediaObjectUIClassname: ~'@{documentNamespace}-media';

	.@{mediaObjectUIClassname}
	{
		margin: 15px 0 -15px;
		max-width: 100%;
		padding-bottom: 15px;
		padding-top: 15px;
		position: relative;
		width: 100%;
		
		@media @big-breakpoints
		{
			padding-bottom: 50px;
			padding-top: 50px;
		}
		
		&:first-child
		{
			padding-top: 0;
		}

		audio, 
		canvas, 
		embed, 
		iframe, 
		img, 
		object, 
		svg, 
		video
		{
			margin: 0;
			max-width: 100%;
			overflow: hidden;
			position: relative;
			width: 100%;
			z-index: 1;
		}
	}