/* ==========================================================================
 *	UI Elements, Buttons
 *
 *	@section UI
 * ========================================================================== */
	@buttonUIClassname: ~'@{documentNamespace}-button';

	.@{buttonUIClassname}
	{
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		align-content: center;
		align-items: center;
		appearance: none;
		background: var(--ui-button-default-bg);
		border-radius: 100vmin;
		border: var(--ui-button-default-bg) solid 1px;
		box-shadow: none;
		box-sizing: border-box;
		color: var(--ui-button-default-color);
		cursor: pointer;
		display: inline-flex;
		font-size: 1rem;
		font-weight: 700;
		height: 70px;
		justify-content: center;
		line-height: 1rem;
		margin: 12.5px 0;
		min-width: 164px;
		overflow: hidden;
		padding: 0 56px;
		position: relative;
		text-align: center;
		text-decoration: none;
		user-select: none;
		vertical-align: middle;
		white-space: nowrap;transition: background-color .2s ease, border-color .2s ease, color .2s ease;

		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		&:hover,
		&:focus,
		&:active
		{
			border-color: var(--ui-button-focus-color);
			color: var(--ui-button-focus-color);
		}

		&:focus:not(:hover)
		{
			text-decoration: underline;
		}
		
		[class*='icon'],
		&__label
		{
			pointer-events: none;
			vertical-align: middle;
		}

		&__label
		{
			[class*='icon']
			{
				line-height: inherit;
				margin-left: 20px;
				vertical-align: middle;
			}
		}

		@media @big-breakpoints
		{
			font-size: 1.250rem;
			height: 82px;
			line-height: 1.250rem;
			min-width: 264px;
			padding: 0 20px;
		}
	}

	.@{buttonUIClassname}--disabled,
	{
		cursor: default;
		pointer-events: none;
		border-color: var(--ui-button-disabled-color);
		color: var(--ui-button-disabled-color);
	}

	.@{buttonUIClassname}.@{buttonUIClassname}--icon
	{
		font-size: 1.5rem;
		min-width: 70px;
		padding: 0 16px;
		
		[class*='icon']
		{
			font-size: inherit;
			margin: 0;
		}

		@media @big-breakpoints
		{
			font-size: 1.75rem;
			min-width: 82px;
		}
	}

	.@{buttonUIClassname}.@{buttonUIClassname}--outline
	{
		background: transparent;
		border: var(--ui-button-default-color) solid 2px;
		color: var(--ui-button-default-color);
		text-decoration: none;

		&:hover,
		&:focus,
		&:active
		{
			background: var(--ui-button-focus-color);
			border-color: var(--ui-button-focus-color);
			color: var(--color-white);
		}
		
		&.@{buttonUIClassname}--disabled
		{
			border-color: var(--ui-button-disabled-color);
			color: var(--ui-button-disabled-color);
		}
	}

	.@{buttonUIClassname}.@{buttonUIClassname}--inverted
	{
		background: var(--ui-button-inverted-bg);
		border: var(--ui-button-inverted-bg) solid 2px;
		color: var(--ui-button-inverted-default-color);
		text-decoration: none;

		&:hover,
		&:focus,
		&:active
		{
			color: var(--ui-button-inverted-focus-color);
		}
		
		&.@{buttonUIClassname}--disabled
		{
			color: var(--ui-button-inverted-disabled-color);
		}
	}

	.@{buttonUIClassname}.@{buttonUIClassname}--small
	{
		font-size: 0.875rem;
		height: 56px;
		line-height: 0.875rem;
		padding: 0 20px;

		@media @big-breakpoints
		{
			font-size: 0.875rem;
			height: 56px;
			line-height: 0.875rem;
			padding: 0 20px;
		}
	}

	.@{buttonUIClassname}.@{buttonUIClassname}--collapse
	{
		min-width: 0;
	}