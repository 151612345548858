/* ==========================================================================
 *	Global Document Rules
 *
 *	@section Document
 *
 *	@notes
 *		While a document can only have one HTML, one HEAD and one BODY element to
 *		be standards compliant, it's still important that BEM-driven classes be
 *		assgned to those elements for the sake of consistency and to prevent any
 *		potential unintented bleed-through in the future.
 * ========================================================================== */
	@documentClassname: ~'@{documentNamespace}-document';

	.@{documentClassname}
	{
		background-color: var(--document-bg);
		color: var(--document-color);
		height: 100%;
		margin: 0;
		overscroll-behavior: none;
		padding: 0;
		transition: background-color 1s ease, color 1s ease;
		width: 100%;
			
		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		.@{documentClassname}__body
		{
			background: transparent;
			display: block;
			margin: 0;
			min-height: 100%;
			overscroll-behavior: none;
			padding: 0;
			width: 100%;
		}
		
		[hidden]
		{
			display: none;
		}
	}

	@documentViewClassname: ~'@{documentNamespace}-view';

	.@{documentViewClassname}	
	{
		box-sizing: border-box;
		contains: paint;
		display: block;
		flex: 1;
		min-height: calc(100vh - var(--header-landmark-height));
		width: 100%;

		&[hidden]
		{
			display: none !important;
		}
	}
	
	.@{headerLandmarkClassname}--fixed ~ .@{documentViewClassname}	
	{
		padding-top: var(--header-landmark-height);
	}

	@documentRegionClassname: ~'@{documentNamespace}-section';
	@documentRegionChildClassname: ~'@{documentRegionClassname}__child';
	@documentRegionContentClassname: ~'@{documentRegionClassname}__content';

	.@{documentRegionClassname}
	{
		align-items: flex-start;
		border: 0;
		box-shadow: none;
		box-sizing: border-box;
		clear: both;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		max-width: 100%;
		min-height: 100%;
		outline: 0;
		padding: 15px @small-breakpoints-gutter;
		position: relative;
		scroll-snap-align: start;
		text-align: left;
		width: 100%;
		z-index: 2;
		
		@media @small-breakpoints
		{
			[data-nebula-present='true'] &
			{
				padding-right: ~"calc(35px + @{small-breakpoints-gutter})";
			}
		}

		@media @big-breakpoints
		{
			padding: ~'50px calc(50vw - (@{big-breakpoint-snap} / 2))';
		}

		@media (min-width: 960px) and (max-width: @big-breakpoint-fat-snap)
		{
			padding-left: @big-breakpoints-gutter;
			padding-right: @big-breakpoints-gutter;
		}

		.@{documentRegionChildClassname}
		{
			align-items: flex-start;
			border: 0;
			box-shadow: none;
			box-sizing: border-box;
			clear: both;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			margin: 0 ~'calc(@{small-breakpoints-gutter} * -1)';
			max-width: ~'calc(100% + (@{small-breakpoints-gutter} * 2))';
			min-height: 100%;
			outline: 0;
			padding: 15px @small-breakpoints-gutter;
			position: relative;
			text-align: left;
			width: ~'calc(100% + (@{small-breakpoints-gutter} * 2))';
			z-index: 2;
				
			&:first-of-type
			{
				margin-top: -15px;
			}
			
			&:last-of-type
			{
				margin-bottom: -15px;
			}

			@media @small-breakpoints
			{
				[data-nebula-present='true'] &
				{
					margin-right: ~'calc((35px + @{small-breakpoints-gutter}) * -1)';
					padding-right: ~'calc(35px + @{small-breakpoints-gutter})';
				}
			}

			@media @big-breakpoints
			{
				margin-left: ~'calc((50vw - (@{big-breakpoint-snap} / 2)) * -1)';
				margin-right: ~'calc((50vw - (@{big-breakpoint-snap} / 2)) * -1)';
				max-width: ~'calc(100% + ((50vw - (@{big-breakpoint-snap} / 2))) * 2)';
				padding-left: ~'calc(50vw - (@{big-breakpoint-snap} / 2))';
				padding-right: ~'calc(50vw - (@{big-breakpoint-snap} / 2))';
				width: ~'calc(100% + ((50vw - (@{big-breakpoint-snap} / 2))) * 2)';
				
				&:first-of-type
				{
					margin-top: -50px;
				}
				
				&:last-of-type
				{
					margin-bottom: -50px;
				}
			}

			@media (min-width: 960px) and (max-width: @big-breakpoint-fat-snap)
			{
				margin-left: ~'calc(@{big-breakpoints-gutter} * -1)';
				margin-right: ~'calc(@{big-breakpoints-gutter} * -1)';
				max-width: ~'calc(100% + (@{big-breakpoints-gutter} * 2))';
				padding-left: @big-breakpoints-gutter;
				padding-right: @big-breakpoints-gutter;
				width: ~'calc(100% + (@{big-breakpoints-gutter} * 2))';
			}
		}
		
		.@{documentRegionContentClassname}
		{
			box-sizing: border-box;
			max-width: 100%;
			width: 100%;
			
			&--offset
			{
				margin-top: -40px;

				@media @big-breakpoints
				{
					margin-top: -64px;
				}
			}
		}
	}
	
	@gridClassname: ~'@{documentNamespace}-grid';
	@gridColClassname: ~'@{documentNamespace}-col';
	@gridRowClassname: ~'@{documentNamespace}-row';
	
	.@{gridRowClassname}
	{
		align-items: center;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-evenly;
		width: 100%;

		@media @big-breakpoints
		{
			flex-direction: row;
		}
	}

	@documentBlockClassname: ~'@{documentNamespace}-block';

	.@{documentBlockClassname}
	{
		
	}

/* ==========================================================================
 *	Anayltics Tracking Pixel Fixes
 * ========================================================================== */
	img[src*='turn.com'],
	img[src*='adsrvr.org'],
	.@{footerLandmarkClassname} ~ img[src^="http"]
	{
		height: 0.01px;
		opacity: 0.01;
		position: absolute;
		width: 0.01px;
		z-index: -1;
	}
	
/* ==========================================================================
 *	508 Skip To Content Link
 * ========================================================================== */
	@508SkiplinkClassname: ~'@{documentNamespace}-508-anchor';

	.@{508SkiplinkClassname}
	{
		box-sizing: border-box;
		color: var(--color-white) !important;
		height: 0;
		left: 0;
		opacity: 0;
		outline: 0;
		overflow: hidden;
		padding: 0;
		position: fixed;
		top: var(--header-landmark-height);
		width: 100%;
		z-index: -1;
		
		&:focus
		{
			background: var(--color-brand-blue);
			height: auto;
			opacity: 1;
			padding: 10px 20px;
			z-index: 100;
		}
	}

/* ==========================================================================
 *	Resource Download Links
 * ========================================================================== */
	@resourceLinkClassname: ~'@{documentNamespace}-resource-link';

	.@{resourceLinkClassname}
	{
		color: inherit;
		display: inline-block;
		text-decoration: underline;
		vertical-align: middle;
		
		[class*='icon']
		{
			font-size: 150%;
			margin-left: -.125em;
			vertical-align: -.25em;
		}
	}
