/* ==========================================================================
 *	Local Page, Overview Section
 *
 *	@section Views
 * ========================================================================== */
	@localViewId: ~'local';

	#@{localViewId}
	{
		@overviewSectionId: ~'overview';

		#@{overviewSectionId}
		{
			background-color: var(--color-black);
			color: var(--color-white);
		}
	}
	
/* ==========================================================================
 *	Local Page, Overview Section, Hero
 *
 *	@section Views
 * ========================================================================== */
 	#@{localViewId}
	{
		@overviewSectionId: ~'overview';

		#@{overviewSectionId}
		{
			@overviewHeroSectionId: ~'hero';

			#@{overviewHeroSectionId}
			{
				background-image: linear-gradient(90deg, rgba(0,0,0,.3) 35%, rgba(0,0,0,0) 70%), url('/src/views/local/media/hero.bg.ls.jpg');
				background-position: 50% 25%;
				background-repeat: no-repeat;
				background-size: cover;
				color: inherit;
				font-size: 0.875rem;
				line-height: 1.25rem;

				@media (orientation: portrait)
				{
					background-image: url('/src/views/local/media/hero.bg.pt.jpg');
					background-position: 50%;
				}

				@media @middle-breakpoints
				{
					font-size: 1.375rem;
					line-height: 2.25rem;
					padding-top: 90px;
					padding-bottom: 90px;
				}

				@media @big-breakpoints
				{
					font-size: 1.375rem;
					line-height: 2.25rem;
					padding-top: 90px;
					padding-bottom: 90px;
				}
					
				.@{documentNamespace}-heading
				{
					font-size: 1.791rem;
					line-height: 1.791rem;
					letter-spacing: 0.01em;

					@media @middle-breakpoints
					{
						font-size: 6.000rem;
						line-height: 1;
						letter-spacing: 0;
					}

					@media @big-breakpoints
					{
						font-size: 6.000rem;
						line-height: 1;
						letter-spacing: 0;
					}

					sup:not([class])
					{
						display: inline-block;
						font-size: 40%;
						line-height: 0;
						transform: translateY(-1em);
						vertical-align: baseline;

						@media @middle-breakpoints
						{
							font-size: 35%;
							transform: translateY(-1.2em);
						}
					}
				}
			}
		}
	}
	
/* ==========================================================================
 *	Local Page, Overview Section, Intro
 *
 *	@section Views
 * ========================================================================== */
	#@{localViewId}
	{
		@overviewSectionId: ~'overview';

		#@{overviewSectionId}
		{
			@overviewIntroSectionId: ~'intro';

			#@{overviewIntroSectionId}
			{
				background-color: var(--color-brand-blue);
				color: var(--color-white);
				font-size: 0.750rem;
				letter-spacing: 0.010rem;
				line-height: 1.250rem;
				padding-bottom: 120px;
				padding-top: 140px;
				text-align: center;
				transition: background-color 1s ease, color 1s ease;
			
				@media (prefers-reduced-motion)
				{
					transition: none;
				}

				@media @big-breakpoints
				{
					font-size: 1.375rem;
					letter-spacing: -0.015rem;
					line-height: 2.250rem;
					padding: ~'110px calc(50vw - (672px / 2)) 140px';
				}
				
				.@{headingUIClassname}
				{
					clear: both;
					display: block;
					font-size: 1.625rem;
					font-weight: 700;
					line-height: 1.750rem;
					margin-bottom: 20px;

					@media @big-breakpoints
					{
						font-size: 1.750rem;
						line-height: 2.125rem;
						margin-bottom: 20px;
					}
				}
				
				p
				{
					margin: 0;

					sup:not([class])
					{
						display: inline-block;
						font-size: 50%;
						line-height: 0;
						position: relative;
						vertical-align: baseline;
						transform: translateY(-0.525em);
					}
					
					.@{referenceUIClassname}
					{
						transform: translateY(-6px);

						@media @big-breakpoints
						{
							transform: translateY(-9px);
						}
					}
				}

				@zipLookupIntroSectionId: ~'zip-lookup';
				
				#@{zipLookupIntroSectionId}
				{
					clear: both;
					display: block;
					position: relative;
					margin-top: 45px;
					margin-bottom: 15px;

					@media @big-breakpoints
					{
						margin-bottom: 30px;
						margin-top: 30px;
					}

					.@{documentNamespace}-module__input
					{
						display: block;
						position: relative;

						&-native
						{
							background: transparent;
							border: 0;
							border-bottom: var(--color-white) solid 1px;
							box-sizing: border-box;
							color: var(--color-white);
							font-family: var(--type-font-sans);
							font-size: 1.625rem;
							font-weight: 700;
							letter-spacing: 0.15rem;
							line-height: 1.75rem;
							max-width: 100%;
							outline: 0;
							padding-bottom: 10px;
							text-align: center;
							transition: opacity .2s ease;
							width: 260px;
							
							@media (prefers-reduced-motion)
							{
								transition: none;
							}

							@media @big-breakpoints
							{
								border-bottom-width: 2px;
								font-size: 1.75rem;
								line-height: 2.125rem;
							}

							&[readonly]
							{
								color: transparent;
								pointer-events: none;
							}
						}
						
						&-label
						{
							bottom: 0;
							box-sizing: border-box;
							display: block;
							font-size: 1.375rem;
							height: 100%;
							left: 50%;
							letter-spacing: 0.03rem;
							line-height: 2.25rem;
							max-width: 100%;
							padding-bottom: 10px;
							pointer-events: none;
							position: absolute;
							text-align: center;
							top: 0;
							transform: translateX(-50%);
							white-space: nowrap;
							width: 260px;

							sup
							{
								font-size: 50%;
								vertical-align: text-top;
								display: inline-block;
								transform: translateY(-.8em);
							}
						}

						.@{documentNamespace}-module__input-native:focus ~ .@{documentNamespace}-module__input-label,
						.@{documentNamespace}-module__input-native--filled ~ .@{documentNamespace}-module__input-label,
						.@{documentNamespace}-module__input-native[readonly] ~ .@{documentNamespace}-module__input-label
						{
							display: none;
						}

						&-placeholder
						{
							bottom: 0;
							box-sizing: border-box;
							display: none;
							font-size: 1.625rem;
							font-weight: 700;
							height: 100%;
							left: 50%;
							letter-spacing: 0.15rem;
							max-width: 100%;
							opacity: 0.5;
							padding-bottom: 10px;
							pointer-events: none;
							position: absolute;
							text-align: center;
							top: 0;
							transform: translateX(-50%);
							white-space: nowrap;
							width: 260px;
						}

						.@{documentNamespace}-module__input-native:focus:not(.@{documentNamespace}-module__input-native--filled) ~ .@{documentNamespace}-module__input-placeholder
						{
							display: block;
						}
						
						.@{documentNamespace}-module__input-native[readonly] ~ .@{documentNamespace}-module__input-placeholder
						{
							display: none;
						}

						&-spinner
						{
							background: transparent url('/src/media/icons/loading.white.svg') 50% 50% / 200px no-repeat;
							bottom: 0;
							box-sizing: border-box;
							display: none;
							font-size: 1.375rem;
							height: 100%;
							height: 2.25rem;
							left: 50%;
							letter-spacing: 0.03rem;
							max-width: 100%;
							padding-bottom: 10px;
							pointer-events: none;
							position: absolute;
							text-align: center;
							top: 0;
							transform: translateX(-50%);
							white-space: nowrap;
							width: 260px;
						}

						.@{documentNamespace}-module__input-native[readonly] ~ .@{documentNamespace}-module__input-spinner
						{
							display: block;
						}
					}

					.@{documentNamespace}-module__messaging
					{
						display: inline-block;
						font-size: 0.875rem;
						line-height: 1.25rem;
						margin-top: 15px;
						max-width: 260px;
						text-align: center;
						vertical-align: middle;
						width: 100%;
						
						[class*='icon']
						{
							font-size: 92%;
							line-height: 1rem;
							vertical-align: middle;
						}

						sup
						{
							font-size: 50%;
							vertical-align: text-top;
							display: inline-block;
							transform: translateY(-.5em);
						}
					}
				}
			}
		}
	}

/* ==========================================================================
 *	Local Page, Video Section
 *
 *	@section Views
 * ========================================================================== */
	#@{localViewId}
	{
		@watchSectionId: ~'watch';

		#@{watchSectionId}
		{
			padding-bottom: 50px;

			@media @small-breakpoints
			{
				min-height: 0;
				padding-left: 15px;
				padding-right: 15px;
			}
		
			.@{headingUIClassname}
			{
				letter-spacing: -0.02em;
				margin-top: 50px;
				text-align: left;
				width: 100%;

				@media @small-breakpoints
				{
					box-sizing: border-box;
					padding-left: 15px;
					padding-right: 15px;
				}

				@media @big-breakpoints
				{
					margin-top: 100px;
					text-align: center;
				}
			}

			.@{documentNamespace}-media
			{
				margin: 15px 0 35px;
				max-width: 100%;
				padding-bottom: 0;
				position: relative;
				width: 100%;

				@media @big-breakpoints
				{
					margin-top: 40px;
				}
				
				&__video
				{
					margin: 0;
					max-width: 100%;
					overflow: hidden;
					position: relative;
					width: 100%;
					z-index: 1;

					@media @big-breakpoints
					{
						border-radius: 12px;
					}
					
					&[inert],
					&[tabindex='-1']
					{
						pointer-events: none;
					}
				}
				
				&__poster
				{
					background-color: transparent;
					background-position: 50% 50%;
					background-repeat: no-repeat;
					background-size: cover;
					border: 0;
					bottom: 0;
					box-shadow: var(--color-brand-light-blue) 0 0 0 0 inset;
					box-sizing: border-box;
					cursor: pointer;
					display: block;
					height: 100%;
					left: 0;
					margin: 0;
					opacity: 1;
					outline: 0;
					padding: 0;
					position: absolute;
					right: 0;
					top: 0;
					transition: box-shadow .2s ease;
					width: 100%;
					z-index: 2;

					@media (prefers-reduced-motion)
					{
						transition: none;
					}

					@media @big-breakpoints
					{
						border-radius: 12px;
						overflow: hidden;
					}

					&:hover,
					&:focus,
					&:active
					{
						box-shadow: var(--color-brand-light-blue) 0 0 0 6px inset;
					}

					&:active
					{
						box-shadow: var(--color-brand-blue) 0 0 0 6px inset;
					}
					
					&[hidden]
					{
						display: none;
					}

					&::after
					{
						background: var(--color-black);
						bottom: 0;
						content: "";
						display: block;
						height: 100%;
						left: 0;
						opacity: .36;
						pointer-events: none;
						position: absolute;
						right: 0;
						top: 0;
						width: 100%;
						z-index: 0;
						transition: background-color .2s ease;

						@media (prefers-reduced-motion)
						{
							transition: none;
						}
					}

					&:hover,
					&:focus,
					&:active
					{
						&::after
						{
							background: var(--color-white);
						}
					}
					
					&-label
					{
						color: var(--color-white);
						display: block;
						font-size: 0.87rem;
						font-weight: bold;
						left: 0;
						line-height: 1.375rem;
						pointer-events: none;
						position: absolute;
						text-align: center;
						text-shadow: rgba(0,0,0,.5) 0 0 2px;
						top: 50%;
						width: 100%;

						@media @big-breakpoints
						{
							font-size: 1.625rem;
							line-height: 2.000rem;
						}
						
						&-icon
						{
							clear: both;
							display: block;
							margin: -28.5px auto 15px;
							pointer-events: none;
							width: 42px;

							@media @big-breakpoints
							{
								margin-top: -38.5px;
								margin-bottom: 25px;
								width: 52px;
							}
						}
					}
				}
			}
		}
	}

	/* ==========================================================================
	 *	Local Page, Contact Callout Section
	 *
	 *	@section Views
	 * ========================================================================== */
		#@{localViewId}
		{
			@contactCTASectionId: ~'contact-cta';
	
			#@{contactCTASectionId}
			{
				font-size: 1.458rem;
				line-height: 1.771rem;
				padding-bottom: 75px;
				padding-top:	75px;
				text-align: left;
	
				@media @big-breakpoints
				{
					font-size: 1.375rem;
					line-height: 1.875rem;
					padding-bottom: 100px;
					padding-top:	132px;
				}

				.@{headingUIClassname}
				{
					letter-spacing: -0.02em;
					text-align: left;
					width: 100%;
	
					@media @big-breakpoints
					{
						text-align: center;
					}
				}

				.@{buttonUIClassname}.@{buttonUIClassname}
				{
					font-size: 1rem;
					min-width: 190px;
				}

				.@{gridRowClassname}
				{
					&:last-of-type {
						margin-top: 1rem;
					}
				}
			}
		}

	/* ==========================================================================
	 *	Local Page, Big Metric Section
	 *
	 *	@section Views
	 * ========================================================================== */
		#@{localViewId}
		{
			@bigMetricSectionId: ~'big-metric';
	
			#@{bigMetricSectionId}
			{
				background-color: var(--color-black);
				background-image: url('/src/regions/benefits/media/bg.ls.png');
				background-position: 50% 100%;
				background-repeat: no-repeat;
				background-size: cover;
				color: var(--color-white);
				font-size: 0.750rem;
				letter-spacing: 0.010rem;
				line-height: 1.250rem;
				padding-bottom: 20px;
				padding-top: 0;	
				text-align: center;
				transition: background-color 1s ease, color 1s ease;
		
				@media (orientation: portrait)
				{
					background-image: url('/src/regions/benefits/media/bg.pt.png');
				}
		
				@media (prefers-reduced-motion)
				{
					transition: none;
				}
		
				@media @big-breakpoints
				{
					background-position: 50% 0;
					font-size: 1.375rem;
					letter-spacing: -0.015rem;
					line-height: 2.250rem;
					padding-bottom: 80px;
					
					@supports (background-attachment: fixed)
					{
						background-attachment: fixed;
					}
				}

				.@{headingUIClassname}
				{
					letter-spacing: -0.02em;
					text-align: left;
					width: 100%;
	
					@media @big-breakpoints
					{
						margin-top: 150px;
						text-align: center;
					}
				}

				.@{documentNamespace}-graphic-text
				{
					clear: both;
					display: block;
					font-size: 3.125rem;
					font-weight: bold;
					letter-spacing: -0.01rem;
					line-height: 3.125rem;
					margin-bottom: 100px;
					margin-top: 120px;
					text-align: left;

					@media @big-breakpoints
					{
						font-size: 4.688rem;
						letter-spacing: 0.025rem;
						line-height: 5.000rem;
						margin-bottom: 140px;
						margin-top: 140px;
					}
					
					mark
					{
						background: transparent;
						color: var(--ui-document-mark-color);
						position: relative;
						transition: color 1s ease;
						
						@media (prefers-reduced-motion)
						{
							transition: none;
						}
						
						&::after
						{
							border-top: var(--ui-document-mark-decoration-color) solid .1em;
							content: '';
							height: 0;
							left: 0;
							position: absolute;
							top: 100%;
							transform: translateY(calc(-100% - 0.025em));
							width: 100%;
							z-index: -1;
						}
					}
					
					.@{referenceUIClassname}
					{
						font-size: 25%;
						transform: translateY(-2.2em);
					}
				}
			}
		}