/* ==========================================================================
 *	Footer
 *
 *	@section Landmarks
 * ========================================================================== */
	@footerLandmarkClassname: ~'@{documentClassname}__footer';

	.@{footerLandmarkClassname}
	{
		align-items: center;
		background-color: var(--include-footer-bg);
		box-sizing: border-box;
		clear: both;
		color: var(--include-footer-color);
		display: block;
		font-size: 0.500rem;
		line-height: 0.625rem;
		padding: 40px calc(@small-breakpoints-gutter / 2);
		position: relative;
		transition: background-color 1s ease, color 1s ease;
		width: 100%;
		z-index: 4;
		
		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		@media @big-breakpoints
		{
			font-size: 0.750rem;
			line-height: 1.000rem;
			padding: 80px calc(50vw - (@big-breakpoint-snap / 2));
		}

		@media (min-width: 960px) and (max-width: @big-breakpoint-fat-snap)
		{
			padding: 60px @big-breakpoints-gutter;
		}
	}