/* ==========================================================================
 *	 UI Elements, Text Fields
 *
 *	@section UI
 * ========================================================================== */
	@textfieldUIClassname: ~'@{documentNamespace}-textfield';

	.@{textfieldUIClassname}
	{
		display: inline-block;
		flex: 0 1 100%;
		font-family: var(--type-font-sans);
		margin: 15px 0;
		min-height: 1px;
		position: relative;
		vertical-align: middle;
		width: 100%;

		// Text Field Native Input
		&__native
		{
			@size: 50px;
		
			background: var(--ui-textfield-default-bg);
			border: 0;
			border-radius: 0;
			border-bottom: var(--ui-textfield-default-border) solid 1px;
			box-shadow: none;
			box-sizing: border-box;
			color: var(--ui-textfield-default-color);
			font-family: var(--type-font-sans);
			font-size: 0.938rem;
			font-weight: 400;
			height: @size;
			line-height: 1;
			outline: 0;
			padding: 10px 20px;
			position: relative;
			text-align: left;
			transition: all .2s ease;
			width: 100%;
			z-index: 1;
				
			@media (prefers-reduced-motion)
			{
				transition: none;
			}

			@media @big-breakpoints
			{
				@size: 80px;
			
				border-bottom-width: 2px;
				font-size: 1.125rem;
				height: @size;
				width: 100%;
			}

			&[data-native~='area']
			{
				max-height: calc(@size * 5);
				max-width: 100%;
				min-height: calc(@size * 1.5);
				resize: none;
				transition: border-color .2s ease, padding .2s ease;
				width: 100% !important;
				
				@media (prefers-reduced-motion)
				{
					transition: none;
				}
			}

			&::-ms-clear
			{
				display: none;
				height: 0;
				width: 0;
			}

			&:-webkit-autofill
			{
				box-shadow: var(--ui-textfield-default-bg) 0 0 @size @size inset;
				border-color: var(--ui-textfield-focus-border);
				padding-top: 1.250rem;
				padding-bottom: 0;
			}

			&:-moz-autofill
			{
				box-shadow: var(--ui-textfield-default-bg) 0 0 @size @size inset;
				border-color: var(--ui-textfield-focus-border);
				padding-top: 1.250rem;
				padding-bottom: 0;
			}

			&:focus,
			&--filled
			{
				border-color: var(--ui-textfield-focus-border);
				padding-top: 20px;
				padding-bottom: 0;

				&[data-native~='area']
				{
					padding-top: 28px;

					@media @big-breakpoints
					{
						padding-top: 40px;
					}
				}
			}

			&--has-helper
			{
				padding-top: 20px;
				padding-bottom: 0;

				@media @big-breakpoints
				{
					padding-top: 40px;
				}
			}
			
			&--invalid, 
			.@{textfieldUIClassname}--invalid  &
			{
				border-color: var(--ui-textfield-error-border);
			}
		}

		// Text Field Helper Text
		&__helper
		{
			color: var(--ui-textfield-default-helper-color);
			font-size: 0.813rem;
			left: 0;
			line-height: 0.938rem;
			overflow: hidden;
			padding: 5px 0;
			pointer-events: none;
			position: absolute;
			text-overflow: ellipsis;
			top: 100%;
			white-space: nowrap;
			width: 100%;
			z-index: 2;

			@media @big-breakpoints
			{
				font-size: 1rem;
				line-height: 1.125rem;
				margin-bottom: ~'calc((10px + 1.125rem) * -1)';
				padding: 5px 20px;
				width: 100%;
			}

			.@{textfieldUIClassname}__native--filled ~ &,
			.@{textfieldUIClassname}--invalid &
			{
				display: none;
			}
		}

		// Text Field Label
		&__label
		{
			color: var(--ui-textfield-default-label-color);
			display: inline-block;
			font-size: 0.938rem;
			left: 20px;
			line-height: 1;
			overflow: hidden;
			pointer-events: none;
			position: absolute;
			text-align: left;
			text-overflow: ellipsis;
			top: 24px;
			transform: translateY(-50%);
			white-space: nowrap;
			width: calc(100% - 1.250rem);
			z-index: 2;
			transition: all .2s ease;
			
			@media (prefers-reduced-motion)
			{
				transition: none;
			}

			@media @big-breakpoints
			{
				font-size: 1.125rem;
				left: 20px;
				top: 40px;
				line-height: 1;
			}
			
			.@{textfieldUIClassname}--invalid &
			{
				color: var(--ui-textfield-error-color);
			}

			.@{textfieldUIClassname}__native:-webkit-autofill ~ &
			{
				font-size: 0.750rem;
				line-height: 1;
				top: 10px;
				transform: translateY(0);

				@media @big-breakpoints
				{
					font-size: 0.875rem;
					line-height: 1;
					top: 15px;
				}
			}

			.@{textfieldUIClassname}__native:-moz-autofill ~ &
			{
				font-size: 0.750rem;
				line-height: 1;
				top: 10px;
				transform: translateY(0);

				@media @big-breakpoints
				{
					font-size: 0.875rem;
					line-height: 1;
					top: 15px;
				}
			}

			.@{textfieldUIClassname}__native[value]:not(:placeholder-shown) ~ &
			{
				font-size: 0.750rem;
				line-height: 1;
				top: 10px;
				transform: translateY(0);

				@media @big-breakpoints
				{
					font-size: 0.875rem;
					line-height: 1;
					top: 15px;
				}
			}

			.@{textfieldUIClassname}__helper ~ &,
			.@{textfieldUIClassname}__native:focus ~ &,
			.@{textfieldUIClassname}__native--filled ~ &
			{
				font-size: 0.750rem;
				line-height: 1;
				top: 10px;
				transform: translateY(0);

				@media @big-breakpoints
				{
					font-size: 0.875rem;
					line-height: 1;
					top: 15px;
				}
			}
		}
	}