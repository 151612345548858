/* ==========================================================================
 *	UI Elements, Loading Spinner
 *
 *	@section UI
 * ========================================================================== */
	@spinnerUIClassname: ~'@{documentNamespace}-spinner';

	.@{spinnerUIClassname}
	{
		background-color: transparent;
		background-image: url(/src/media/icons/loading.svg);
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		clear: both;
		display: block;
		height: 20px;
		pointer-events: none;
		position: relative;
		width: 200px;

		[data-supports-animated-svg~='false'] &
		{
		background-image: url(/src/media/icons/loading.gif);
		}
	}