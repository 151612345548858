/* ==========================================================================
 *	UI Elements, Headings
 *
 *	@section Typography
 * ========================================================================== */
	@headingUIClassname: ~'@{documentNamespace}-heading';

	.@{headingUIClassname}
	{
		clear: both;
		color: inherit;
		display: block;
		font-variant-ligatures: common-ligatures;
		letter-spacing: 0.02em;
		margin: 0 0 1.875rem;
		overflow: visible;
		vertical-align: baseline;

		&[aria-level='1']
		{
			font-size: 3.125rem;
			line-height: 3.125rem;
			font-weight: 700;
			margin-bottom: 15px;

			@media @big-breakpoints
			{
				font-size: 6.000rem;
				line-height: 5.250rem;
				margin-bottom: 30px;
			}
		}

		&[aria-level='2']
		{
			font-size: 2.088rem;
			line-height: 2.188rem;
			font-weight: 700;
			margin-bottom: 15px;

			@media @big-breakpoints
			{
				font-size: 3.000rem;
				line-height: 3.250rem;	
				margin-bottom: 30px;
			}
		}

		&[aria-level='3']
		{
			font-size: 1.625rem;
			line-height: 1.750rem;
			font-weight: 700;
			margin-bottom: 15px;

			@media @big-breakpoints
			{
				font-size: 1.750rem;
				line-height: 2.125rem;	
				margin-bottom: 30px;
			}
		}

		&[aria-level='4']
		{
			font-size: 1.146rem;
			line-height: 1.313rem;
			font-weight: 700;
			margin-bottom: 15px;

			@media @big-breakpoints
			{
				font-size: 1.750rem;
				line-height: 2.125rem;	
				margin-bottom: 30px;
			}
		}

		&[aria-level='5'],
		&[aria-level='6']
		{
			font-size: 1.042rem;
			line-height: 1.125rem;
			font-weight: 700;

			@media @big-breakpoints
			{
				font-size: 1.375rem;
				line-height: 1.875rem;
			}
		}

		sup
		{
			display: inline-block;
			font-size: 34%;
			margin: 0;
			padding: 0;
			position: relative;
			vertical-align: top;
			transform: translateY(-20%);
		}

		&[aria-level='1'] sup
		{
			font-size: 25%;
			transform: translateY(-25%);
		}
	}