/* ==========================================================================
 *	UI Elements, radiocards
 *
 *	@section UI
 * ========================================================================== */
	@radiocardUIClassname: ~'@{documentNamespace}-radiocard';
	@radiocardOptionUIClassname: ~'@{radiocardUIClassname}__option';
	@radiocardOptionLabelUIClassname: ~'@{radiocardOptionUIClassname}-label';
	@radiocardOptionLabelHelperUIClassname: ~'@{radiocardOptionLabelUIClassname}-helper';
	
	.@{radiocardUIClassname}
	{
		border: 0;
		clear: both;
		float: left;
		margin: 0;
		margin: 15px 0 45px;
		outline: 0;
		padding: 0;
		width: 100%;
		
		&__decorator
		{
			box-sizing: border-box;
			clear: both;
			color: var(--ui-radiocard-label-color); 
			display: block;
			font-size: 1rem;
			font-weight: 700;
			line-height: 1rem;
			margin-bottom: 20px;
			position: relative;
			text-align: left;

			@media @big-breakpoints
			{
				font-size: 1.500rem;
				line-height: 1.500rem;
				margin-bottom: 20px;
			}
		}
	
		&__label
		{
			box-sizing: border-box;
			clear: both;
			color: var(--ui-radiocard-label-color);
			display: block;
			font-size: 1.500rem;
			font-weight: 400;
			line-height: 2.000rem;
			margin-bottom: 0;
			padding-top: 20px;
			position: relative;
			text-align: left;
			min-height: 18px;

			&::before
			{
				border-bottom: var(--color-brand-blue) solid 2px;
				content: '';
				display: block;
				height: 0;
				left: 0;
				position: absolute;
				top: 0;
				width: 30px;
			}

			@media @big-breakpoints
			{
				margin-bottom: 15px;
				min-height: calc(2rem + (25px * 2) + (15px / 2));
				padding-top: 25px;
			}
		}
	
		&__helper
		{
			color: var(--ui-radiocard-label-helper-color);
			box-sizing: border-box;
			clear: both;
			display: block;
			font-size: 1rem;
			line-height: 1.125rem;
			margin-bottom: 15px; 
			margin-top: 0;

			@media @big-breakpoints
			{
				font-size: 1.125rem;
				line-height: 1.25rem;
				margin-bottom: 15px; 
				margin-top: -5px;
			}
		}

		&__option
		{
			align-items: flex-start;
			box-sizing: border-box;
			clear: both;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			padding: 0 40px;
			justify-content: center;
			margin-top: 20px;
			min-height: 80px;
			position: relative;

			&-visual
			{
				background-color: var(--ui-radiocard-default-bg);
				border-radius: 100vmin;
				border: solid 1px var(--ui-radiocard-default-border);
				box-sizing: border-box;
				color: #202020;
				cursor: pointer;
				display: inline-block;
				flex: 0 1 auto;
				font-size: 1rem;
				height: 100%;
				left: 0;
				overflow: hidden;
				bottom: 0;
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 0;
				transition: background-color .1s ease, border-color .2s ease;
				vertical-align: middle;
				width: 100%;
				z-index: 0;
			
				@media (prefers-reduced-motion)
				{
					transition: none;
				}

				@media @big-breakpoints
				{
					border-width: 2px;
				}

				.@{radiocardUIClassname}__native:focus ~ &,
				.@{radiocardUIClassname}__native:hover ~ &
				{
					background-color: var(--ui-radiocard-focus-bg);
					border-color: var(--ui-radiocard-focus-border);
				}

				.@{radiocardUIClassname}__native:checked ~ &
				{
					background-color: var(--ui-radiocard-checked-bg);
					border-color: var(--ui-radiocard-checked-border);
				}

				.@{radiocardUIClassname}__native:checked:focus ~ &,
				.@{radiocardUIClassname}__native:checked:hover ~ &
				{
					background-color: var(--ui-radiocard-checked-focus-bg);
					border-color: var(--ui-radiocard-checked-focus-border);
				}

				&-check
				{
					align-items: center;
					background: var(--ui-radiocard-checked-color);
					border-radius: 100vmin;
					box-sizing: border-box;
					color: var(--ui-radiocard-checked-bg);
					display: none;
					font-size: 1.25rem;
					height: 2.5rem;
					justify-content: center;
					line-height: 2.5rem;
					overflow: hidden;
					padding: .5rem;
					pointer-events: none;
					position: absolute;
					right: 20px;
					text-align: center;
					top: 50%;
					transform: translateY(-50%);
					width: 2.5rem;
					z-index: 1;
				}

				.@{radiocardUIClassname}__native:checked ~ & &-check
				{
					display: inline-flex;
				}

				.@{radiocardUIClassname}__native:checked:focus ~ & &-check,
				.@{radiocardUIClassname}__native:checked:hover ~ & &-check
				{
					display: inline-flex;
				}
			}
			
			&-label
			{
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				-webkit-user-select: none;
				box-sizing: border-box;
				color: var(--ui-radiocard-default-color);
				font-size: 1rem;
				font-weight: 400;
				line-height:  1.125rem;
				padding-right: 40px;
				pointer-events: none;
				position: relative;
				text-align: left;
				user-select: none;
				vertical-align: middle;
				width: 100%;
				z-index: 1;

				@media @big-breakpoints
				{
					font-size: 1.125rem;
					line-height: 1.250rem;
				}
				
				&-helper
				{
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					-webkit-user-select: none;
					clear: both;
					color: var(--ui-radiocard-default-helper-color);
					display: block;
					font-size: 0.750rem;
					font-weight: 400;
					line-height: 0.875rem;
					pointer-events: none;
					position: relative;
					text-align: left;
					user-select: none;
					vertical-align: middle;
					width: 100%;
					z-index: 1;

					@media @big-breakpoints
					{
						font-size: 1rem;
						line-height: 1.125rem;
					}
				}

				.@{radiocardUIClassname}__native:focus ~ &,
				.@{radiocardUIClassname}__native:hover ~ &
				{
					color: var(--ui-radiocard-focus-color);

					.@{radiocardOptionLabelHelperUIClassname}
					{
						color: var(--ui-radiocard-focus-helper-color);
					}
				}

				.@{radiocardUIClassname}__native:checked ~ &
				{
					color: var(--ui-radiocard-checked-color);
					font-weight: 700;

					.@{radiocardOptionLabelHelperUIClassname}
					{
						color: var(--ui-radiocard-checked-helper-color);
					}
				}

				.@{radiocardUIClassname}__native:checked:focus ~ &,
				.@{radiocardUIClassname}__native:checked:hover ~ &
				{
					color: var(--ui-radiocard-checked-focus-color);

					.@{radiocardOptionLabelHelperUIClassname}
					{
						color: var(--ui-radiocard-checked-focus-helper-color);
					}
				}
			}
		}
		
		&__native
		{
			clear: both;
			cursor: pointer;
			display: inline-block;
			height: 100%;
			left: 0;
			margin: 0;
			opacity: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;
		}
		
		&--invalid .@{radiocardOptionUIClassname}-visual
		{
			border-color: var(--ui-radiocard-error-border) !important;
		}
		
		.@{errorUIClassname}
		{
			margin-bottom: 0;
			margin-top: 0;
			padding-left: 0;
			padding-right: 0;

			@media @big-breakpoints
			{
				margin-top: -10px;
			}
		}
	}