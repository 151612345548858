/* ==========================================================================
 *	UI Elements, Footnotes
 *
 *	@section UI
 * ========================================================================== */
	@footnotesUIClassname: ~'@{documentNamespace}-footnotes';
	@footnotesListUIClassname: ~'@{footnotesUIClassname}__list';
	@footnotesListItemUIClassname: ~'@{footnotesListUIClassname}-item';

	.@{footnotesUIClassname}
	{
		color: var(--ui-footnotes-color);
		clear: both;
		display: block;
		text-align: left;
		
		.@{footnotesListUIClassname}
		{
			counter-reset: footnotes;
			display: block;
			list-style: none;
			margin: 0 0 40px;
			padding: 0;

			&-item
			{
				box-sizing: border-box;
				clear: both;
				display: block;
				list-style: none;
				margin: 0 0 15px;
				padding: 0 0 0 calc(1rem + 6px);
				position: relative;
				width: 100%;
				
				&::before
				{
					color: var(--ui-footnotes-counter-color);
					content: counter(footnotes)".";
					counter-increment: footnotes;
					display: inline-block;
					font-size: inherit;
					left: 0;
					line-height: inherit;
					position: absolute;
					top: 0;
					width: 1rem;
				}
				
				[href]:not(.@{footnotesListUIClassname}-return)
				{
					color: inherit;
					display: inline-block;
					position: relative;
					text-decoration: underline;
					transition: color .2s ease;
					word-break: break-word;
				
					@media (prefers-reduced-motion)
					{
						transition: none;
					}

					&:hover,
					&:focus, 
					&:active
					{
						border: 0;
						outline: 0;
						text-decoration: none;
					}

					&:hover
					{
						color: var(--color-brand-light-blue);
					}

					&:focus:not(:hover)::before
					{
						background-color: var(--color-brand-light-blue);
						border-radius: 3px;
						bottom: -3px;
						color: inherit;
						content: '';
						display: inline-block;
						left: -3px;
						opacity: 1;
						overflow: hidden;
						pointer-events: none;
						position: absolute;
						right: -3px;
						top: -3px;
						z-index: -1;
					}
				}
			}

			&-return
			{
				background: transparent;
				border-radius: 0;
				border: 0;
				box-shadow: none;
				color: inherit;
				cursor: pointer;
				display: inline-block;
				outline: 0;
				position: relative;

				&:hover,
				&:focus, 
				&:active
				{
					border: 0;
					outline: 0;
					text-decoration: none;
				}

				&:hover
				{
					color: var(--color-brand-light-blue);
				}

				&:focus:not(:hover)::before
				{
					background-color: var(--color-brand-light-blue);
					border-radius: 3px;
					bottom: -3px;
					color: inherit;
					content: '';
					display: inline-block;
					left: -3px;
					opacity: 1;
					overflow: hidden;
					pointer-events: none;
					position: absolute;
					right: -3px;
					top: -3px;
					z-index: -1;
				}
				
				&[hidden]
				{
					display: none;
				}

				&[inert]
				{
					pointer-events: none;
				}

				[class*='icon']
				{
					box-sizing: border-box;
					font-size: 14px;
					margin: -3px -3px -6px;
					padding: 3px;
					transform: rotate(-90deg) translateX(4px);
					vertical-align: bottom;
				}
			}
			
			sup
			{
				font-size: 66%;
				position: relative;
				top: -0.45em;
				vertical-align: 0;
			}
		}
	}