/* ==========================================================================
 *	Navigation
 *
 *	@section Landmarks
 * ========================================================================== */
	@navLandmarkClassname: ~'@{documentNamespace}-nav';
	@navMenuLandmarkClassname: ~'@{navLandmarkClassname}__menu';
	@navMenuGroupLandmarkClassname: ~'@{navMenuLandmarkClassname}-group';
	@navMenuItemLandmarkClassname: ~'@{navMenuLandmarkClassname}-item';
	@navMenuItemLabelLandmarkClassname: ~'@{navMenuItemLandmarkClassname}-label';

	.@{navLandmarkClassname}
	{
		@media @small-breakpoints
		{
			background-color: var(--include-header-bg);
			bottom: 0;
			color: var(--include-nav-color);
			display: block;
			height: calc(100% - var(--header-landmark-height));
			left: 0;
			pointer-events: none;
			position: fixed;
			right: 0;
			top: var(--header-landmark-height);
			transform: translateY(-100%);
			transition: transform .35s ease;
			z-index: 3;
		
			@media (prefers-reduced-motion)
			{
				transition: none;
			}

			.@{navTriggerLandmarkClassname}:checked ~ &
			{
				pointer-events: auto;
				transform: translateY(0);
			}

			.@{navMenuLandmarkClassname}
			{
				align-items: flex-start;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				height: auto;
				justify-content: flex-start;
				left: 0;
				overflow: auto;
				padding: calc(60px * 1.5) 44px 60px;
				position: absolute;
				top: 0;
				width: 100%;

				[data-nebula-present='true'] &
				{
					padding-right: 79px;
				}
			}

			.@{navMenuGroupLandmarkClassname}
			{
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				flex: 0 1 auto;
				justify-content: flex-start;
				text-align: center;
				width: 100%;

				@media (max-height: 568px)
				{
					flex: 0 1 calc((1.250rem * 6) + (8vmin * 6));
				}

				@media (orientation: landscape)
				{
					flex-direction: row;
					flex-wrap: wrap;
				}
			}

			.@{navMenuItemLandmarkClassname}
			{
				color: var(--include-nav-color);
				flex: 1 1 auto;
				margin-bottom: 40px;
				opacity: 0;
				text-decoration: none;
				transform: translateY(50%);
				width: 100%;

				.@{navTriggerLandmarkClassname}:checked ~ &
				{
					opacity: 1;
					transform: translateY(0);
					transition: opacity .25s ease .35s,transform .35s ease .35s;

					@media (prefers-reduced-motion)
					{
						transition: none;
					}

					&:nth-child(2):not([class*='--solo'])
					{
						transition-delay: .45s;

						@media (prefers-reduced-motion)
						{
							transition: none;
						}
					}

					&:nth-child(3):not([class*='--solo'])
					{
						transition-delay: .55s;

						@media (prefers-reduced-motion)
						{
							transition: none;
						}
					}

					&:nth-child(4):not([class*='--solo'])
					{
						transition-delay: .65s;

						@media (prefers-reduced-motion)
						{
							transition: none;
						}
					}
				}

				@media (max-height: 568px)
				{
					margin-bottom: 25px;
				}

				&:not(.@{navMenuItemLandmarkClassname}--solo)
				{
					font-size: 1.500rem;
					line-height: 1;

					@media (max-height: 568px)
					{
						font-size: 1.250rem;
					}
					
					&.@{navMenuItemLandmarkClassname}--current
					{
						.@{navMenuItemLabelLandmarkClassname}
						{
							position: relative;

							&::after
							{
								border-top: var(--include-nav-color) solid 2px;
								content: '';
								display: block;
								height: 0;
								left: 0;
								position: absolute;
								top: 100%;
								width: 100%;
							}
						}
					}
				}

				&--solo
				{
					background-color: var(--include-nav-button-default-bg);
					border-color: var(--include-nav-button-default-color);
					border-radius: 100vmin;
					border: var(--include-nav-button-default-border) solid 1px;
					box-sizing: border-box;
					flex: 0 1 auto;
					font-weight: 500;
					margin-left: auto;
					margin-right: auto;
					opacity: 0;
					padding: 16px 56px;
					text-align: center;
					text-decoration: none;
					transform: translateY(50%);
					transition: background-color .2s ease, border-color .2s ease, color .2s ease, opacity .25s ease, transform .25s ease !important;
					width: auto;

					@media (prefers-reduced-motion)
					{
						transition: none;
					}

					.@{navTriggerLandmarkClassname}:checked ~ &
					{
						opacity: 1;
						transform: translateY(0);
						transition: background-color .2s ease, border-color .2s ease, color .2s ease, opacity .25s ease 0.65s, transform .25s ease 0.65s !important;

						@media (prefers-reduced-motion)
						{
							transition: none;
						}
					}

					&:hover,
					&:focus,
					&[data-current]
					{
						background-color: var(--include-nav-button-focus-bg);
						border-color: var(--include-nav-button-focus-border);
						color: var(--include-nav-button-focus-color);
						text-decoration: none;
					}

					&:last-child
					{
						margin-top: 5px;

						@media @small-breakpoints
						{
							margin-top: 45px;
						}
					}
				}

				&:last-child
				{
					margin-bottom: 0;
				}

				&--current:not(.@{navMenuItemLandmarkClassname}--solo) .@{navMenuItemLabelLandmarkClassname}::after
				{
					width: 100%;
				}
			}
		}

		@media @big-breakpoints
		{
			display: inline-block;
			height: 100%;
			margin-left: auto;

			.@{navMenuLandmarkClassname}
			{
				align-items: center;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				height: 100%;
				justify-content: center;
				vertical-align: middle;

				.@{navMenuGroupLandmarkClassname}
				{
					position: relative;

					.@{navMenuItemLandmarkClassname}
					{
						overflow: visible;
						position: relative;
						z-index: 3;
						
						&-fx
						{
							border-top: var(--include-nav-color) solid 2px;
							bottom: -4px;
							content: '';
							display: inline-block;
							height: 0;
							left: 0;
							pointer-events: none;
							position: absolute;
							right: 0;
							z-index: 4;
							transition: left .2s ease, right .2s ease;

							@media (prefers-reduced-motion)
							{
								transition: none;
							}

							&[data-animate-direction='right']:not([data-returning])
							{
								transition: left .2s ease .2s, right .2s ease;

								@media (prefers-reduced-motion)
								{
									transition: none;
								}
							}

							&[data-animate-direction='left']:not([data-returning])
							{
								transition: left .2s ease, right .2s ease .2s;

								@media (prefers-reduced-motion)
								{
									transition: none;
								}
							}
						}
					}
					
					&--no-fx
					{
						.@{navMenuItemLandmarkClassname}
						{
							&::after
							{
								border-top: var(--include-nav-color) solid 2px;
								bottom: -4px;
								content: '';
								display: inline-block;
								height: 0;
								left: 50%;
								pointer-events: none;
								position: absolute;
								transform: translateX(-50%);
								transition: width .2s ease;
								width: 0;
								z-index: 4;

								@media (prefers-reduced-motion)
								{
									transition: none;
								}
							}
							
							&:hover::after,
							&:focus::after
							{
								width: 100%;
							}
						}
					}
				}

				.@{navMenuItemLandmarkClassname}
				{
					box-sizing: border-box;
					color: var(--include-nav-color);
					font-size: 0.813rem;
					font-weight: 700;
					letter-spacing: 0.020em;
					line-height: 1.000rem;
					margin-left: 50px;
					padding: 0;
					position: relative;
					text-decoration: none;
					vertical-align: middle;
					transition: background-color .2s ease, border-color .2s ease, color .2s ease, margin .2s ease;

					@media (prefers-reduced-motion)
					{
						transition: none;
					}

					@media @very-big-breakpoints
					{
						margin-left: 100px;
					}
					
					&:first-child
					{
						margin-left: 0;
					}

					&--solo
					{
						background-color: var(--include-nav-button-default-bg);
						border-color: var(--include-nav-button-default-color);
						border-radius: 100vmin;
						border: var(--include-nav-button-default-border) solid 1px;
						font-weight: 500;
						padding: 16px 56px;
						text-decoration: none;
						transition: background-color .2s ease, border-color .2s ease, color .2s ease, margin .2s ease;

						@media (prefers-reduced-motion)
						{
							transition: none;
						}

						@media @big-breakpoints
						{
							padding: 12px 22px;
						}

						&:hover,
						&:focus,
						&[data-current]
						{
							background-color: var(--include-nav-button-focus-bg);
							border-color: var(--include-nav-button-focus-border);
							color: var(--include-nav-button-focus-color);
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	@import '/src/includes/navigation/navigation.trigger.less';