/* ==========================================================================
 *	Post-Content Navigation
 *
 *	@section Landmarks
 * ========================================================================== */
	@postnavLandmarkClassname: ~'@{documentClassname}__postnav';
	@postnavMenuLandmarkClassname: ~'@{postnavLandmarkClassname}-menu';
	@postnavMenuGroupLandmarkClassname: ~'@{postnavMenuLandmarkClassname}group';
	@postnavMenuItemLandmarkClassname: ~'@{postnavMenuLandmarkClassname}item';
	@postnavMenuItemLabelLandmarkClassname: ~'@{postnavMenuItemLandmarkClassname}-label';
	
	.@{postnavLandmarkClassname}
	{
		align-items: center;
		background-color: var(--include-postnav-bg);
		box-sizing: border-box;
		clear: both;
		color: var(--include-postnav-color);
		display: block;
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 1.250rem;
		padding: 40px calc(@small-breakpoints-gutter / 2) 0;
		position: relative;
		transition: background-color 1s ease, color 1s ease;
		width: 100%;
		z-index: 4;

		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		@media @middle-breakpoints
		{
			font-size: 1.000rem;
			line-height: 1.250rem;
			padding: 50px calc(@small-breakpoints-gutter / 2) 0;
		}

		@media @big-breakpoints
		{
			font-size: 1.000rem;
			line-height: 1.250rem;
			padding: 90px calc(50vw - (@big-breakpoint-snap / 2)) 0;
		}

		@media (min-width: 960px) and (max-width: @big-breakpoint-fat-snap)
		{
			padding: 90px @big-breakpoints-gutter 70px;
		}
		
		//- Menus
		&-menu
		{
			align-items: flex-start;
			clear: both;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;

			@media @middle-breakpoints
			{
				flex-direction: row;
			}

			@media @big-breakpoints
			{
				flex-direction: row;
			}

			&group
			{
				align-items: flex-start;
				box-sizing: border-box;
				break-inside: auto;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: flex-start;

				@media @middle-breakpoints
				{
					flex-direction: row;
				}

				@media @big-breakpoints
				{
					flex-direction: row;
				}

				&:not(:first-child)
				{
					@media @small-breakpoints
					{
						margin-top: 25px;
					}

					@media @middle-breakpoints
					{
						border-left: var(--include-postnav-border) solid 1px;
						margin-top: 0;
						padding-left: 100px;
					}

					@media @big-breakpoints
					{
						border-left: var(--include-postnav-border) solid 1px;
						margin-left: -15px;
						padding-left: 135px;
					}
				}
			}

			&prop
			{
				display: block;
				width: 100%;

				@media @middle-breakpoints
				{
					box-sizing: border-box;
					min-width: 125px;
					padding-right: 10px;
				}

				@media @big-breakpoints
				{
					box-sizing: border-box;
					min-width: 250px;
					padding-right: 20px;
					width: auto;
				}
			}

			.@{postnavMenuItemLandmarkClassname}
			{
				border: 0;
				box-shadow: none;
				clear: both;
				color: inherit;
				display: block;
				margin-bottom: 18px;
				outline: 0;
				text-align: left;
				text-decoration: none;
				transition: color .2s ease;

				@media (prefers-reduced-motion)
				{
					transition: none;
				}
				
				.@{postnavMenuItemLabelLandmarkClassname}
				{
					box-sizing: border-box;
					display: inline-block;
					position: relative;

					@media @middle-breakpoints
					{
						padding-bottom: 2px;
					}

					@media @big-breakpoints
					{
						padding-bottom: 2px;
					}

					&::after
					{
						border-bottom: var(--include-postnav-color) solid 1px;
						bottom: 0;
						content: '';
						display: block;
						left: 0;
						pointer-events: none;
						position: absolute;
						transition: width .2s ease;
						width: 0;

						@media (prefers-reduced-motion)
						{
							transition: none;
						}

						@media @middle-breakpoints
						{
							border-bottom-width: 2px;
						}

						@media @big-breakpoints
						{
							border-bottom-width: 2px;
						}
					}
				}

				&:focus,
				&:hover
				{
					.@{postnavMenuItemLabelLandmarkClassname}::after
					{
						width: 100%;
					}
				}
			}
		}

		//- Branding Element
		.@{brandUIClassname}
		{
			margin-bottom: 35px;

			@media @middle-breakpoints
			{
				margin-bottom: 50px;
				width: 275px;
			}

			@media @big-breakpoints
			{
				margin-bottom: 80px;
				width: 394px;
			}
		}
		
		//- Footnotes
		.@{footnotesUIClassname}
		{
			border-bottom: var(--include-postnav-border) solid 1px;
			box-sizing: border-box;
			font-size: 0.500rem;
			line-height: 0.625rem;
			padding-bottom: 40px;

			@media @middle-breakpoints
			{
				padding-bottom: 70px;
				font-size: 0.750rem;
				line-height: 1.000rem;
			}

			@media @big-breakpoints
			{
				padding-bottom: 70px;
				font-size: 0.750rem;
				line-height: 1.000rem;
			}
		}
	}