/* ==========================================================================
 *	Utility Selectors
 *	
 *	@section Utilities
 * ========================================================================== */

// Assistive-Technology Elements
	[data-only-screenreader]
	{
		background: transparent !important;
		border: 0 !important;
		box-shadow: none !important;
		clip: rect(0,0,0,0) !important;
		color: transparent !important;
		font-size: 0.001px !important;
		height: 0.001px !important;
		line-height: 0 !important;
		margin: -0.001px !important;
		max-height: 0.001px !important;
		max-width: 0.001px !important;
		min-height: 0.001px !important;
		min-width: 0.001px !important;
		opacity: 0.001 !important;
		outline: 0 !important;
		overflow: hidden !important;
		padding: 0 !important;
		pointer-events: none !important;
		position: absolute !important;
		width: 0.001px !important;
		z-index: -2 !important;
	}

// Viewport Size Breakpoints
	@breakpoint_micro: ~'(max-width: 299px)';
	@breakpoint_extra-small: ~'(min-width: 300px) and (max-width: 599px)'; 
	@breakpoint_small: ~'(min-width: 600px) and (max-width: 959px)'; 
	@breakpoint_medium: ~'(min-width: 960px) and (max-width: 1279px)'; 
	@breakpoint_large: ~'(min-width: 1280px) and (max-width: 1919px)'; 
	@breakpoint_extra-large: ~'(min-width: 1920px) and (max-width: 3839px)'; 
	@breakpoint_super-large: ~'(min-width: 3840px)';

	@breakpoints: {
		u:  @breakpoint_micro;
		xs: @breakpoint_extra-small; 
		sm: @breakpoint_small; 
		md: @breakpoint_medium; 
		lg: @breakpoint_large;
		xl: @breakpoint_extra-large;
		xx: @breakpoint_super-large;
	}

	[data-only-breakpoint]
	{
		each(@breakpoints, {
			@media @value
			{
				&:not([data-only-breakpoint~='@{key}'])
				{
					display: none !important;
					pointer-events: none !important;
					speak: none !important;
				}
			}
		})
	}

	@very-small-breakpoints: 
		@breakpoint_micro, 
		@breakpoint_extra-small;

	@small-breakpoints: 
		@breakpoint_micro, 
		@breakpoint_extra-small, 
		@breakpoint_small; 

	@middle-breakpoints: 
		@breakpoint_small,
		@breakpoint_medium, 
		@breakpoint_large;

	@big-breakpoints: 
		@breakpoint_medium, 
		@breakpoint_large, 
		@breakpoint_extra-large, 
		@breakpoint_super-large;

	@very-big-breakpoints: 
		@breakpoint_large, 
		@breakpoint_extra-large, 
		@breakpoint_super-large;

// Experience Sizes
	@small-breakpoints-gutter: 30px;
	@big-breakpoints-gutter: 40px;
	@big-breakpoint-snap: 1110px;
	@big-breakpoint-fat-snap: 1190px;

// Viewport Orientation Breakpoints
	@orientations: {
		ls: ~'(orientation: landscape)';
		pt: ~'(orientation: portrait)';
		sq: ~'(orientation: square)';
	}

	[data-only-orientation]
	{
		each(@orientations, {
			@media @value
			{
				&:not([data-only-orientation~='@{key}'])
				{
					display: none !important;
					pointer-events: none !important;
					speak: none !important;
				}
			}
		})
	}