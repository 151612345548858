/* ==========================================================================
 *	 UI Elements, Select Dropdowns
 *
 *	@section UI
 * ========================================================================== */
	@dropdownUIClassname: ~'@{documentNamespace}-dropdown';
	@dropdownNativeUIClassname: ~'@{dropdownUIClassname}__native';
	
	.@{dropdownUIClassname}
	{
		display: inline-block;
		flex: 0 1 100%;
		margin: 15px 0;
		min-height: 1px;
		position: relative;
		vertical-align: middle;
		width: 100%;

		.@{dropdownNativeUIClassname}
		{
			-webkit-appearance: none;
			appearance: none;
			background: var(--ui-dropdown-default-bg);
			border: 0;
			border-bottom: var(--ui-dropdown-default-border) solid 1px;
			border-radius: 0;
			box-shadow: none;
			box-sizing: border-box;
			color: var(--ui-dropdown-default-color);
			cursor: pointer;
			font-size: 1.125rem;
			height: 50px;
			line-height: 1.1;
			outline: 0;
			padding: 10px 43px 10px 20px;
			position: relative;
			text-align: left;
			transition: all .2s ease;
			width: 100%;
			z-index: 1;
				
			@media (prefers-reduced-motion)
			{
				transition: none;
			}
	
			&::-ms-expand
			{
				display: none;
			}

			&:-webkit-autofill
			{
				border-color: var(--ui-dropdown-focus-border);
				padding-top: 1.250rem;
				padding-bottom: 0;
			}

			&:-moz-autofill
			{
				border-color: var(--ui-dropdown-focus-border);
				padding-top: 1.250rem;
				padding-bottom: 0;
			}

			&:focus,
			&--filled
			{
				border-color: var(--ui-dropdown-focus-border);
				padding-top: 1.250rem;
				padding-bottom: 0;
			}
			
			&::-ms-value
			{
				background: var(--ui-dropdown-default-bg);
				color: var(--ui-dropdown-default-color);
				padding-left: 10px;
			}

			@media @big-breakpoints
			{
				border-bottom-width: 2px;
				font-size: 1.125rem;
				height: 80px;
				width: 100%;
			}
			
			option
			{
				padding-left: 0;
				padding-right: 0;
			}
		}

		&--invalid
		{
			.@{dropdownNativeUIClassname}
			{
				border-color: var(--ui-dropdown-error-border) !important;
			}
		}
		
		&__icon
		{
			-moz-backface-visibility: hidden;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			color: inherit;
			pointer-events: none;
			position: absolute;
			right: 15px;
			top: 15px;
			z-index: 2;

			@media @big-breakpoints
			{
				top: 30px;
			}

			.@{dropdownNativeUIClassname}:focus ~ &,
			.@{dropdownNativeUIClassname}--filled ~ &
			{
				color: var(--ui-dropdown-focus-border);
			}
			
			.@{dropdownUIClassname}--invalid &
			{
				color: var(--ui-dropdown-error-color) !important;
			}
		}

		// Text Field Label
		&__label
		{
			color: var(--ui-dropdown-default-label-color);
			display: inline-block;
			font-size: 0.938rem;
			left: 20px;
			line-height: 1;
			overflow: hidden;
			pointer-events: none;
			position: absolute;
			text-align: left;
			text-overflow: ellipsis;
			top: 24px;
			transform: translateY(-50%);
			transition: all .2s ease;
			white-space: nowrap;
			width: calc(100% - 1.250rem);
			z-index: 2;
				
			@media (prefers-reduced-motion)
			{
				transition: none;
			}
			
			.@{dropdownUIClassname}--invalid &
			{
				color: var(--ui-dropdown-error-color);
			}

			.@{dropdownUIClassname}__native:-webkit-autofill ~ &
			{
				font-size: 0.750rem;
				line-height: 1;
				top: 10px;
				transform: translateY(0);
			}

			.@{dropdownUIClassname}__native:-moz-autofill ~ &
			{
				font-size: 0.750rem;
				line-height: 1;
				top: 10px;
				transform: translateY(0);
			}

			.@{dropdownUIClassname}__native[value]:not(:placeholder-shown) ~ &
			{
				font-size: 0.750rem;
				line-height: 1;
				top: 10px;
				transform: translateY(0);
			}

			.@{dropdownUIClassname}__native:focus ~ &,
			.@{dropdownUIClassname}__native--filled ~ &
			{
				font-size: 0.750rem;
				line-height: 1;
				top: 10px;
				transform: translateY(0);
			}

			@media @big-breakpoints
			{
				font-size: 1.125rem;
				left: 20px;
				top: 40px;
				line-height: 1;

				.@{dropdownUIClassname}__native:webkit-autofill ~ &
				{
					font-size: 0.875rem;
					line-height: 1;
					top: 25px;
				}

				.@{dropdownUIClassname}__native:-moz-autofill ~ &
				{
					font-size: 0.875rem;
					line-height: 1;
					top: 25px;
				}

				.@{dropdownUIClassname}__native[value]:not(:placeholder-shown) ~ &
				{
					font-size: 0.875rem;
					line-height: 1;
					top: 25px;
				}

				.@{dropdownUIClassname}__native:focus ~ &, 
				.@{dropdownUIClassname}__native--filled ~ &
				{
					font-size: 0.875rem;
					line-height: 1;
					top: 15px;
				}
			}
		}
	}