/* ==========================================================================
 *	UI Elements, Branding Element
 *
 *	@section UI
 * ========================================================================== */
	@brandUIClassname: ~'@{documentNamespace}-brand';

	.@{brandUIClassname}
	{
		align-items: center;
		display: inline-flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		max-width: 50%;
		width: 173px;
		
		&[tabindex='-1']
		{
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			-webkit-tap-highlight-color: transparent;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			pointer-events: none;
		}

		@media @big-breakpoints
		{
			width: 232px;
		}

		img
		{
			display: block;
			margin: 0;
			pointer-events: none;
			width: 100%;
		}
	}