/* ==========================================================================
 *	National Page, Overview Section
 *
 *	@section Views
 * ========================================================================== */
	@nationalViewId: ~'national';

	#@{nationalViewId}
	{
		@overviewSectionId: ~'overview';

		#@{overviewSectionId}
		{
			background-color: var(--color-black);
			color: var(--color-white);
		}
	}
	
/* ==========================================================================
 *	National Page, Overview Section, Hero
 *
 *	@section Views
 * ========================================================================== */
 	#@{nationalViewId}
	{
		@overviewSectionId: ~'overview';

		#@{overviewSectionId}
		{
			@overviewHeroSectionId: ~'hero';

			#@{overviewHeroSectionId}
			{
				background-image: linear-gradient(90deg, rgba(0,0,0,.3) 35%, rgba(0,0,0,0) 70%), url('/src/views/national/media/hero.bg.ls.jpg');
				background-position: 50% 25%;
				background-repeat: no-repeat;
				background-size: cover;
				color: inherit;
				font-size: 0.875rem;
				line-height: 1.25rem;

				@media (orientation: portrait)
				{
					background-image: url('/src/views/national/media/hero.bg.pt.jpg');
					background-position: 50%;
				}

				@media @middle-breakpoints
				{
					font-size: 1.375rem;
					line-height: 2.25rem;
					padding-top: 90px;
					padding-bottom: 90px;
				}

				@media @big-breakpoints
				{
					font-size: 1.375rem;
					line-height: 2.25rem;
					padding-top: 90px;
					padding-bottom: 90px;
				}
					
				.@{documentNamespace}-heading
				{
					font-size: 1.791rem;
					line-height: 1.791rem;
					letter-spacing: 0.01em;

					@media @middle-breakpoints
					{
						font-size: 6.000rem;
						line-height: 1;
						letter-spacing: 0;
					}

					@media @big-breakpoints
					{
						font-size: 6.000rem;
						line-height: 1;
						letter-spacing: 0;
					}

					sup:not([class])
					{
						display: inline-block;
						font-size: 40%;
						line-height: 0;
						transform: translateY(-1em);
						vertical-align: baseline;

						@media @middle-breakpoints
						{
							font-size: 35%;
							transform: translateY(-1.2em);
						}
					}
				}
			}
		}
	}
	
/* ==========================================================================
 *	National Page, Overview Section, Intro
 *
 *	@section Views
 * ========================================================================== */
	#@{nationalViewId}
	{
		@overviewSectionId: ~'overview';

		#@{overviewSectionId}
		{
			@overviewIntroSectionId: ~'intro';

			#@{overviewIntroSectionId}
			{
				background-color: var(--color-brand-blue);
				color: var(--color-white);
				font-size: 0.750rem;
				letter-spacing: 0.010rem;
				line-height: 1.250rem;
				padding-bottom: 120px;
				padding-top: 140px;
				text-align: center;
				transition: background-color 1s ease, color 1s ease;
			
				@media (prefers-reduced-motion)
				{
					transition: none;
				}

				@media @big-breakpoints
				{
					font-size: 1.375rem;
					letter-spacing: -0.015rem;
					line-height: 2.250rem;
					padding: ~'110px calc(50vw - (672px / 2)) 140px';
				}
				
				.@{headingUIClassname}
				{
					clear: both;
					display: block;
					font-size: 1.625rem;
					font-weight: 700;
					line-height: 1.750rem;
					margin-bottom: 20px;

					@media @big-breakpoints
					{
						font-size: 1.750rem;
						line-height: 2.125rem;
						margin-bottom: 20px;
					}
				}
				
				p
				{
					margin: 0;

					sup:not([class])
					{
						display: inline-block;
						font-size: 50%;
						line-height: 0;
						position: relative;
						vertical-align: baseline;
						transform: translateY(-0.525em);
					}
					
					.@{referenceUIClassname}
					{
						transform: translateY(-6px);

						@media @big-breakpoints
						{
							transform: translateY(-9px);
						}
					}
				}

				@zipLookupIntroSectionId: ~'zip-lookup';
				
				#@{zipLookupIntroSectionId}
				{
					clear: both;
					display: block;
					position: relative;
					margin-top: 45px;
					margin-bottom: 15px;

					@media @big-breakpoints
					{
						margin-bottom: 30px;
						margin-top: 30px;
					}

					.@{documentNamespace}-module__input
					{
						display: block;
						position: relative;

						&-native
						{
							background: transparent;
							border: 0;
							border-bottom: var(--color-white) solid 1px;
							box-sizing: border-box;
							color: var(--color-white);
							font-family: var(--type-font-sans);
							font-size: 1.625rem;
							font-weight: 700;
							letter-spacing: 0.15rem;
							line-height: 1.75rem;
							max-width: 100%;
							outline: 0;
							padding-bottom: 10px;
							text-align: center;
							transition: opacity .2s ease;
							width: 260px;
							
							@media (prefers-reduced-motion)
							{
								transition: none;
							}

							@media @big-breakpoints
							{
								border-bottom-width: 2px;
								font-size: 1.75rem;
								line-height: 2.125rem;
							}

							&[readonly]
							{
								color: transparent;
								pointer-events: none;
							}
						}
						
						&-label
						{
							bottom: 0;
							box-sizing: border-box;
							display: block;
							font-size: 1.375rem;
							height: 100%;
							left: 50%;
							letter-spacing: 0.03rem;
							line-height: 2.25rem;
							max-width: 100%;
							padding-bottom: 10px;
							pointer-events: none;
							position: absolute;
							text-align: center;
							top: 0;
							transform: translateX(-50%);
							white-space: nowrap;
							width: 260px;

							sup
							{
								font-size: 50%;
								vertical-align: text-top;
								display: inline-block;
								transform: translateY(-.8em);
							}
						}

						.@{documentNamespace}-module__input-native:focus ~ .@{documentNamespace}-module__input-label,
						.@{documentNamespace}-module__input-native--filled ~ .@{documentNamespace}-module__input-label,
						.@{documentNamespace}-module__input-native[readonly] ~ .@{documentNamespace}-module__input-label
						{
							display: none;
						}

						&-placeholder
						{
							bottom: 0;
							box-sizing: border-box;
							display: none;
							font-size: 1.625rem;
							font-weight: 700;
							height: 100%;
							left: 50%;
							letter-spacing: 0.15rem;
							max-width: 100%;
							opacity: 0.5;
							padding-bottom: 10px;
							pointer-events: none;
							position: absolute;
							text-align: center;
							top: 0;
							transform: translateX(-50%);
							white-space: nowrap;
							width: 260px;
						}

						.@{documentNamespace}-module__input-native:focus:not(.@{documentNamespace}-module__input-native--filled) ~ .@{documentNamespace}-module__input-placeholder
						{
							display: block;
						}
						
						.@{documentNamespace}-module__input-native[readonly] ~ .@{documentNamespace}-module__input-placeholder
						{
							display: none;
						}

						&-spinner
						{
							background: transparent url('/src/media/icons/loading.white.svg') 50% 50% / 200px no-repeat;
							bottom: 0;
							box-sizing: border-box;
							display: none;
							font-size: 1.375rem;
							height: 100%;
							height: 2.25rem;
							left: 50%;
							letter-spacing: 0.03rem;
							max-width: 100%;
							padding-bottom: 10px;
							pointer-events: none;
							position: absolute;
							text-align: center;
							top: 0;
							transform: translateX(-50%);
							white-space: nowrap;
							width: 260px;
						}

						.@{documentNamespace}-module__input-native[readonly] ~ .@{documentNamespace}-module__input-spinner
						{
							display: block;
						}
					}

					.@{documentNamespace}-module__messaging
					{
						display: inline-block;
						font-size: 0.875rem;
						line-height: 1.25rem;
						margin-top: 15px;
						max-width: 260px;
						text-align: center;
						vertical-align: middle;
						width: 100%;
						
						[class*='icon']
						{
							font-size: 92%;
							line-height: 1rem;
							vertical-align: middle;
						}

						sup
						{
							font-size: 50%;
							vertical-align: text-top;
							display: inline-block;
							transform: translateY(-.5em);
						}
					}
				}
			}
		}
	}