/* ==========================================================================
 *	 UI Elements, Radio Inputs
 *
 *	@section UI
 * ========================================================================== */
	@radioUIClassname: ~'@{documentNamespace}-radio';
	@radioOptionUIClassname: ~'@{radioUIClassname}__option';
	@radioOptionVisualUIClassname: ~'@{radioOptionUIClassname}-visual';
	@radioOptionVisualCheckUIClassname: ~'@{radioOptionVisualUIClassname}-check';

	.@{radioUIClassname}
	{
		border: 0;
		clear: both;
		float: left;
		margin: 0;
		margin: 15px 0 45px;
		outline: 0;
		padding: 0;
		width: 100%;
		
		&__decorator
		{
			box-sizing: border-box;
			clear: both;
			color: var(--ui-radio-label-color);
			display: block;
			font-size: 1rem;
			font-weight: 700;
			line-height: 1rem;
			margin-bottom: 20px;
			position: relative;
			text-align: left;

			@media @big-breakpoints
			{
				font-size: 1.500rem;
				line-height: 1.500rem;
				margin-bottom: 20px;
			}
		}
	
		&__label
		{
			box-sizing: border-box;
			clear: both;
			color: var(--ui-radio-label-color);
			display: block;
			font-size: 1.500rem;
			font-weight: 400;
			line-height: 2.000rem;
			margin-bottom: 0;
			min-height: 18px;
			padding-top: 20px;
			position: relative;
			text-align: left;

			&::before
			{
				border-bottom: var(--color-brand-blue) solid 2px;
				content: '';
				display: block;
				height: 0;
				left: 0;
				position: absolute;
				top: 0;
				width: 30px;
			}

			@media @big-breakpoints
			{
				margin-bottom: 15px;
				min-height: 28px;
				padding-top: 25px;
			}
		}
		
		&__option
		{
			margin-top: 20px;
			align-items: center;
			clear: both;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			float: left;
			justify-content: flex-start;
			min-height: 18px;
			position: relative;

			@media @big-breakpoints
			{
				min-height: 28px;
			}

			&-visual
			{
				background-color: var(--ui-radio-default-bg);
				border-radius: 100%;
				border: solid 1px var(--ui-radio-default-border);
				box-sizing: border-box;
				color: #202020;
				cursor: pointer;
				display: inline-block;
				flex: 0 1 auto;
				font-size: 1rem;
				height: 18px;
				left: 0;
				overflow: hidden;
				pointer-events: none;
				position: absolute;
				top: 0;
				transition: background-color .1s ease, border-color .2s ease;
				vertical-align: middle;
				width: 18px;
				z-index: 1;
				
				@media (prefers-reduced-motion)
				{
					transition: none;
				}

				@media @big-breakpoints
				{
					border-width: 2px;
					height: 28px;
					width: 28px;
				}
				
				&-check
				{
					align-items: center;
					bottom: 0;
					box-sizing: border-box;
					color: var(--ui-radio-default-bg);
					display: none;
					font-size: 1.25rem;
					height: 100%;
					justify-content: center;
					left: 0;
					line-height: 18px;
					overflow: hidden;
					pointer-events: none;
					position: absolute;
					right: 0;
					text-align: center;
					top: 0;
					vertical-align: 4px;
					width: 100%;
					z-index: 1;
				}

				.@{radioUIClassname}__native:focus ~ &,
				.@{radioUIClassname}__native:hover ~ &
				{
					background-color: var(--ui-radio-focus-bg);
					border-color: var(--ui-radio-focus-border);
				}

				.@{radioUIClassname}__native:checked ~ &
				{
					background-color: var(--ui-radio-checked-bg);
					border-color: var(--ui-radio-checked-border);
				}

				.@{radioUIClassname}__native:checked ~ &
				{
					.@{radioOptionVisualCheckUIClassname}
					{
						@media (prefers-contrast: more)
						{
							display: flex;
						}

						@media (-ms-high-contrast: active)
						{
							display: flex;
						}
					}
				}
			}
			
			&-label
			{
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				-webkit-user-select: none;
				color: var(--ui-radio-default-color);
				font-size: 1.125rem;
				line-height: 1;
				padding-left: 38px;
				font-weight: 400;
				pointer-events: none;
				position: relative;
				text-align: left;
				transition: color .2s ease;
				user-select: none;
				vertical-align: middle;
				width: 100%;
				
				@media (prefers-reduced-motion)
				{
					transition: none;
				}

				.@{radioUIClassname}__native:focus ~ &,
				.@{radioUIClassname}__native:hover ~ &
				{
					color: var(--ui-radio-focus-color);
				}

				.@{radioUIClassname}__native:checked ~ &
				{
					color: var(--ui-radio-checked-color);
					font-weight: 700;
				}
			}
		}

		&__native
		{
			clear: both;
			cursor: pointer;
			display: inline-block;
			height: 100%;
			left: 0;
			margin: 0;
			opacity: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;
		}
		
		&--invalid .@{radioOptionUIClassname}-visual
		{
			border-color: var(--ui-radio-error-border) !important;
		}
		
		.@{errorUIClassname}
		{
			margin-bottom: 0;
			margin-top: 0;
			padding-left: 0;
			padding-right: 0;

			@media @big-breakpoints
			{
				margin-top: -10px;
			}
		}
	}