/* ==========================================================================
 *	HTTP Error Pages
 *
 *	@section Views
 * ========================================================================== */
	[id^='40']
	{
		@overviewSectionId: ~'overview';
		
		#@{overviewSectionId}
		{
			background-color: var(--color-brand-blue);
			color: var(--color-white);
			font-size: 0.750rem;
			letter-spacing: 0.010rem;
			line-height: 1.250rem;
			min-height: calc(100vh - var(--header-landmark-height));
			padding-bottom: 120px;
			padding-top: 140px;
			text-align: center;
			transition: background-color 1s ease, color 1s ease;

			@media (prefers-reduced-motion)
			{
				transition: none;
			}

			@media @big-breakpoints
			{
				font-size: 1.375rem;
				letter-spacing: -0.015rem;
				line-height: 2.250rem;
				padding: ~'110px calc(50vw - (672px / 2)) 140px';
			}
			
			.@{headingUIClassname}
			{
				font-size: 2.088rem;
				line-height: 2.188rem;
				font-weight: 700;
				margin-bottom: 15px;

				@media @big-breakpoints
				{
					font-size: 3.000rem;
					line-height: 3.250rem;	
					margin-bottom: 30px;
				}
				
				small
				{
					clear: both;
					display: block;
					font-size: 1.625rem;
					font-weight: 400;
					line-height: 1.750rem;
					margin-bottom: 15px;

					@media @big-breakpoints
					{
						font-size: 1.750rem;
						line-height: 2.125rem;	
						margin-bottom: 30px;
					}
				}
			}

			p
			{
				margin: 0;
				text-decoration-skip-ink: none;

				a
				{
					color: var(--color-white);
					display: inline;
					text-decoration: underline;
					text-decoration-skip-ink: none;
				}

				sup:not([class])
				{
					display: inline-block;
					font-size: 50%;
					line-height: 0;
					position: relative;
					vertical-align: baseline;
					transform: translateY(-0.525em);
				}
			}
		}
	}