/* ==========================================================================
 *	Connect Page
 *
 *	@section Views
 * ========================================================================== */
	@connectViewId: ~'connect';
	@connectCampaignRegionId: ~'campaign';

	.@{documentViewClassname}#@{connectViewId}
	{
		.@{mediaObjectUIClassname}
		{
			@media @big-breakpoints
			{
				border-radius: 12px;
				margin: 0;
				overflow: hidden;
				padding-bottom: 0;
				padding: 0;
			}

			audio, 
			canvas, 
			embed, 
			iframe, 
			img, 
			object, 
			svg, 
			video
			{
				display: block;
			}
		}

		#business-information
		{
			@media @big-breakpoints
			{
				.@{formRowUIClassname}:nth-child(2)
				{
					.@{formColUIClassname}:first-child
					{
						flex-basis: calc(33.3333333% - (50px / 2));
					}
					
					.@{formColUIClassname}:last-child
					{
						flex-basis: calc(66.6666666% - (50px / 2));
						margin-left: 50px;
					}
				}
			}
		}

		//- Campaign-Specific Styles
		#@{connectCampaignRegionId}
		{
			color: var(--color-brand-blue);
			font-size: 0.875rem;
			line-height: 1.125rem;
			order: 2;
			padding-bottom: 0;

			@media @big-breakpoints
			{
				font-size: 1.125rem;
				line-height: 1.750rem;
			}

			.@{mediaObjectUIClassname}
			{
				margin: 0;

				& + .@{documentBlockClassname}
				{
					margin-top: 50px;
				}
			}
		}

		[id$='contact']
		{
			padding-top: 0;
		}

		[id$='-thankyou']
		{
			color: var(--color-brand-blue);
			padding-top: 50px !important;

			p
			{
				sup
				{
					display: inline-block;
					font-size: 50%;
					line-height: 0;
					position: relative;
					transform: translateY(-0.525em);
					vertical-align: baseline;
				}
			}

			.@{documentBlockClassname}
			{
				text-align: left;

				.@{buttonUIClassname}
				{
					&:not(.@{buttonUIClassname}--outline)
					{
						background-color: var(--ui-button-default-color);
						border-color: var(--ui-button-default-color);
						color: var(--color-white);
						
						&:hover,
						&:focus,
						&:active
						{
							background-color: var(--color-brand-light-blue);
							border-color: var(--color-brand-light-blue);
						}
					}
				}

				p
				{
					margin-bottom: 1em;
				}	
			}
		}
	}