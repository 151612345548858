/* ==========================================================================
 *	Returns Page, Overview Section
 *
 *	@section Views
 * ========================================================================== */
	@returnsViewId: ~'returns';

	#@{returnsViewId}
	{
		@overviewSectionId: ~'overview';

		#@{overviewSectionId}
		{
			background-color: var(--color-black);
			color: var(--color-white);
		}
	}
	
/* ==========================================================================
 *	Returns Page, Overview Section, Hero
 *
 *	@section Views
 * ========================================================================== */
 	#@{returnsViewId}
	{
		@overviewSectionId: ~'overview';

		#@{overviewSectionId}
		{
			@overviewHeroSectionId: ~'hero';
			
			#@{overviewHeroSectionId}
			{
					background-image: linear-gradient(90deg, rgba(0,0,0,.66) 35%, rgba(0,0,0,0) 70%), url('/src/views/returns/media/hero.bg.ls.jpg');
					background-position: 50% 75%;
					background-repeat: no-repeat;
					background-size: cover;
					color: inherit;
					font-size: 0.875rem;
					line-height: 1.25rem;
					
					@media (orientation: portrait)
					{
						background-image: linear-gradient(90deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,.5) 100%), url('/src/views/returns/media/hero.bg.pt.jpg');
						background-position: 100% 67%;
					}

					@media @middle-breakpoints
					{
						font-size: 1.375rem;
						line-height: 2.25rem;
						padding-top: 90px;
						padding-bottom: 90px;
					}

					@media @big-breakpoints
					{
						font-size: 1.375rem;
						line-height: 2.25rem;
						padding-top: 90px;
						padding-bottom: 90px;
					}
						
					.@{documentNamespace}-heading
					{
						font-size: 1.791rem;
						line-height: 1.791rem;
						letter-spacing: 0.01em;

						@media @middle-breakpoints
						{
							font-size: 6.000rem;
							line-height: 1;
							letter-spacing: 0;
						}

						@media @big-breakpoints
						{
							font-size: 6.000rem;
							line-height: 1;
							letter-spacing: 0;
						}

						sup:not([class])
						{
							display: inline-block;
							font-size: 40%;
							line-height: 0;
							transform: translateY(-1em);
							vertical-align: baseline;

							@media @middle-breakpoints
							{
								font-size: 35%;
								transform: translateY(-1.2em);
							}
						}
					}
				}
		}
	}
	
/* ==========================================================================
 *	Returns Page, Overview Section, Intro
 *
 *	@section Views
 * ========================================================================== */
	#@{returnsViewId}
	{
		@overviewSectionId: ~'overview';

		#@{overviewSectionId}
		{
			@overviewIntroSectionId: ~'intro';

			#@{overviewIntroSectionId}
			{
				background-color: var(--color-brand-blue);
				color: var(--color-white);
				font-size: 0.750rem;
				letter-spacing: 0.010rem;
				line-height: 1.250rem;
				padding-bottom: 120px;
				padding-top: 140px;
				text-align: center;
				transition: background-color 1s ease, color 1s ease;
				
				@media (prefers-reduced-motion)
				{
					transition: none;
				}

				@media @big-breakpoints
				{
					font-size: 1.375rem;
					letter-spacing: -0.015rem;
					line-height: 2.250rem;
					padding: ~'110px calc(50vw - (672px / 2)) 140px';
				}
				
				.@{headingUIClassname}
				{
					clear: both;
					display: block;
					font-size: 1.625rem;
					font-weight: 700;
					line-height: 1.750rem;
					margin-bottom: 20px;

					@media @big-breakpoints
					{
						font-size: 1.750rem;
						line-height: 2.125rem;
						margin-bottom: 20px;
					}
				}
				
				p
				{
					margin: 0;
						
					sup:not([class])
					{
						display: inline-block;
						font-size: 50%;
						line-height: 0;
						position: relative;
						vertical-align: baseline;
						transform: translateY(-0.525em);
					}
					
					.@{referenceUIClassname}
					{
						transform: translateY(-6px);

						@media @big-breakpoints
						{
							transform: translateY(-9px);
						}
					}
				}
			}
		}
	}