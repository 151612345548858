/* ==========================================================================
 *	How It Works
 *
 *	@section Regions
 * ========================================================================== */
	@hiwSectionId: ~'how-it-works';

	#@{hiwSectionId} 
	{
		@moduleClassname: ~'@{documentNamespace}-module';
	
		background-color: var(--color-brand-blue);
		color: var(--color-white);
		font-size: 0.750rem;
		justify-content: unset;
		letter-spacing: 0.010rem;
		line-height: 1.250rem;
		padding-bottom: 120px;
		padding-top: 140px;
		text-align: center;
		transition: background-color 1s ease, color 1s ease;
			
		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		@media @middle-breakpoints
		{
			font-size: 1.375rem;
			letter-spacing: -0.015rem;
			line-height: 2.250rem;
			padding-bottom: 110px;
			padding-top: 110px;
		}

		@media @big-breakpoints
		{
			font-size: 1.375rem;
			letter-spacing: -0.015rem;
			line-height: 2.250rem;
			padding-bottom: 110px;
			padding-top: 110px;
		}

		.@{headingUIClassname}
		{
			margin-bottom: 100px;
			font-size: 1.625rem;
			line-height: 1.750rem;
			font-weight: 700;

			@media @middle-breakpoints
			{
				font-size: 1.750rem;
				line-height: 2.125rem;	
				margin-bottom: 125px;
			}

			@media @big-breakpoints
			{
				font-size: 1.750rem;
				line-height: 2.125rem;	
				margin-bottom: 150px;
			}
		}

		.@{documentRegionClassname}__prop
		{
			display: block;
			height: calc(40 * (1vw + 1vh - 1vmin));
			pointer-events: none;
			position: relative;
			z-index: -1;

			&:first-of-type
			{
				margin-top: calc((40 * (1vw + 1vh - 1vmin)) * -1);
			}

			&:last-of-type
			{
				margin-bottom: calc(20 * (1vw + 1vh - 1vmin));
			}
		}

		.@{moduleClassname}
		{
			align-items: flex-start;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			padding-top: calc(var(--header-landmark-height) / 2);
			position: sticky;
			top: var(--header-landmark-height);
			width: 100%;

			@media @middle-breakpoints
			{
				flex-direction: row;
				justify-content: space-between;
			}

			@media @big-breakpoints
			{
				flex-direction: row;
				justify-content: space-between;
			}

			@import '/src/regions/how-it-works/how-it-works.menu.less';
			@import '/src/regions/how-it-works/how-it-works.display.less';
			
			&__footer
			{
				clear: both;
				display: block;
				font-size: 0.458rem;
				line-height: 0.625rem;
				margin-top: 15px;

				@media @middle-breakpoints
				{
					bottom: 0;
					font-size: 0.875rem;
					left: 275px;
					line-height: 1.125rem;
					margin-top: 30px;
					position: absolute;
					transform: translateY(calc(100% + 25px));
				}

				@media @big-breakpoints
				{
					bottom: 0;
					font-size: 0.875rem;
					left: 375px;
					line-height: 1.125rem;
					margin-top: 30px;
					position: absolute;
					transform: translateY(calc(100% + 25px));
				}
			}
		}
	}