/* ==========================================================================
 *	Header
 *
 *	@section Landmarks
 * ========================================================================== */
	@headerLandmarkClassname: ~'@{documentClassname}__header';
	
	:root
	{
		--header-landmark-height: 60px;

		@media @big-breakpoints
		{
			--header-landmark-height: 80px;
		}
	}

	.@{headerLandmarkClassname}
	{
		align-items: center;
		background-color: var(--include-header-bg);
		box-sizing: border-box;
		color: var(--include-header-color);
		display: flex;
		flex-direction: row;
		height: var(--header-landmark-height);
		justify-content: flex-start;
		padding: 0 calc(@small-breakpoints-gutter / 2);
		position: relative;
		transition: background-color 1s ease, color 1s ease;
		width: 100%;
		z-index: 5;
		
		&.@{headerLandmarkClassname}--fixed
		{
			position: fixed;
			top: 0;
			left: 0;
		}
		
		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		@media @big-breakpoints
		{
			padding: 0 calc(50vw - (@big-breakpoint-snap / 2));
		}

		@media (min-width: 960px) and (max-width: @big-breakpoint-fat-snap)
		{
			padding: 0 @big-breakpoints-gutter;
		}
		
		.@{brandUIClassname}
		{
			position: relative;
			z-index: 4;
		}
	}