/* ==========================================================================
 *	Typography
 *
 *	@section Typography
 * ========================================================================== */
	@documentTypoClassname: ~'@{documentNamespace}-document';

	.@{documentClassname}
	{
		font-family: var(--type-font-sans);
		font-size: 16px;
		font-weight: 400;
		font-weight: normal;
		line-height: 1.750rem;

		p
		{
			margin: 0;
		}
	}

// Avoid Break Classes
  @avoidBreakTypoClassname: ~'@{documentNamespace}--avoid-break';

  .@{avoidBreakTypoClassname}
  {
    display: inline-block;
  }

// Prevent Break Classes
  @avoidBreakTypoClassname: ~'@{documentNamespace}--prevent-break';

  .@{avoidBreakTypoClassname}
  {
    white-space: nowrap;
  }

// Text Alignment Classes
	@alignSetClassname: ~'@{documentNamespace}--align';

	.@{alignSetClassname}
	{
		@alignments: center, end, justify, left, match-parent, right, start;
		
		each(@alignments, {
			&\(@{value}\)
			{
				text-align: @value !important;
			}
		});
	}

// Font Weight Classes
	@weightTypoClassname: ~'@{documentNamespace}--weight';
	
	.@{weightTypoClassname}
	{
		@weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;
		
		each(@weights, {
			&\(@{value}\)
			{
				font-weight: @value;
			}
		});
	}