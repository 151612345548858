/* ==========================================================================
 *	Cookie Consent Banner
 *
 *	@section Landmarks
 * ========================================================================== */
	@cookieLandmarkClassname: ~'@{documentNamespace}-cookie-consent';
	
	.@{cookieLandmarkClassname}
	{
		align-items: center;
		backface-visibility: hidden;
		bottom: 0;
		box-sizing: border-box;
		color: var(--include-cookie-color);
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		font-size: 0.625rem;
		justify-content: center;
		left: 50%;
		line-height: 0.792rem;
		padding: ~"25px calc(@{small-breakpoints-gutter} / 2)";
		position: fixed;
		text-shadow: rgba(0,0,0,.5) 0 1px 0;
		transform: translateX(-50%);
		width: 100%;
		z-index: 6;
		transition: opacity .5s ease, transform .5s ease;
		
		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		[data-javascript-enabled~='true'] &
		{
			opacity: 0;
			transform: translate(-50%, 100%);
		}

		[data-javascript-enabled~='true'] &[data-ready~='true']
		{
			opacity: 1;
			transform: translate(-50%, 0);
		}

		@media @big-breakpoints
		{
			bottom: 58px;
			font-size: 0.780rem;
			line-height: 01.020rem;
			max-width: 1322px;
			padding: 25px 30px;
			flex-direction: row;
		}

		@media (min-width: 960px) and (max-width: 1382px)
		{
			max-width: calc(100% - 60px);
		}
		
		&::before
		{
			backface-visibility: hidden;
			background-color: var(--include-cookie-legacy-bg);
			bottom: 0;
			box-shadow: rgba(0,0,0,.125) 0 15px 45px;
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: -1;

			@media @big-breakpoints
			{
				border-radius: 10px;
			}
			
			@supports (backdrop-filter: blur(15px))
			{
				backdrop-filter: blur(15px);
				background-color: var(--include-cookie-modern-bg);
			}
		}
		
		&__content
		{
			margin: 0;
			flex: 1 1 auto;

			a
			{
				color: inherit;
				text-decoration: underline;
			}
		}
		
		&__ok
		{
			background-color: var(--include-cookie-button-default-bg);
			border-radius: 5px;
			border: 0;
			box-shadow: none;
			box-sizing: border-box;
			color: var(--include-cookie-button-default-color);
			cursor: pointer;
			flex: 1 1 auto;
			font-family: var(--type-font-sans);
			font-size: 10px;
			font-weight: 700;
			line-height: 1;
			margin-top: 15px;
			max-width: 300px;
			outline: 0;
			overflow: hidden;
			padding: 15px;
			transition: background-color .2s ease, color .2s ease;
			width: 100%;
			
			@media (prefers-reduced-motion)
			{
				transition: none;
			}

			@media @big-breakpoints
			{
				flex: 0 1 auto;
				font-size: 0.875rem;
				line-height: 1.069rem;
				margin-left: 50px;
				margin-top: 0;
				min-width: 148px;
				padding: 15px;
				text-align: center;
				width: auto;
			}
			
			&:hover,
			&:focus
			{	
				background-color: var(--include-cookie-button-focus-bg);
				color: var(--include-cookie-button-focus-color);
			}
		}
	}