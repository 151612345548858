/* ==========================================================================
 *	Post-Content Footnotes
 *
 *	@section Landmarks
 * ========================================================================== */
	@postfootnotesLandmarkId: ~'references';
	
	#@{postfootnotesLandmarkId}
	{
		align-items: center;
		background-color: var(--include-postnav-bg);
		border-bottom: var(--include-postnav-border) solid 1px;
		box-sizing: border-box;
		clear: both;
		color: var(--include-postnav-color);
		display: block;
		font-size: 0.750rem;
		line-height: 1.000rem;
		padding: 40px calc(@small-breakpoints-gutter / 2);
		position: relative;
		transition: background-color 1s ease, color 1s ease;
		width: 100%;
		z-index: 4;

		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		@media @big-breakpoints
		{
			padding: 90px calc(50vw - (@big-breakpoint-snap / 2)) 40px;
		}

		@media (min-width: 960px) and (max-width: @big-breakpoint-fat-snap)
		{
			padding: 90px @big-breakpoints-gutter 40px;
		}
	}