/* ==========================================================================
 *	Benefits
 *
 *	@section Regions
 * ========================================================================== */
	@benefitsSectionId: ~'benefits';
	@benefitsClassName: ~'@{documentNamespace}-benefits';

	#@{benefitsSectionId}
	{
		background-color: var(--color-black);
		background-image: url('/src/regions/benefits/media/bg.ls.png');
		background-position: 50% 100%;
		background-repeat: no-repeat;
		background-size: cover;
		color: var(--color-white);
		font-size: 0.750rem;
		letter-spacing: 0.010rem;
		line-height: 1.250rem;
		padding-bottom: 20px;
		padding-top: 0;	
		text-align: center;
		transition: background-color 1s ease, color 1s ease;

		@media (orientation: portrait)
		{
			background-image: url('/src/regions/benefits/media/bg.pt.png');
		}
		
		@media (prefers-reduced-motion)
		{
			transition: none;
		}

		@media @big-breakpoints
		{
			background-position: 50% 0;
			font-size: 1.375rem;
			letter-spacing: -0.015rem;
			line-height: 2.250rem;
			padding-bottom: 80px;
			
			@supports (background-attachment: fixed)
			{
				background-attachment: fixed;
			}
		}

		.@{benefitsClassName}
		{
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			font-size: 0.667rem;
			justify-content: flex-start;
			letter-spacing: 0.01rem;
			line-height: 1.042rem;

			@media @middle-breakpoints
			{
				align-items: stretch;
				flex-direction: row;
				flex-wrap: wrap;
				font-size: 0.875rem;
				justify-content: space-between;
				line-height: 1.313rem;
			}

			@media @big-breakpoints
			{
				align-items: stretch;
				flex-direction: row;
				flex-wrap: wrap;
				font-size: 0.875rem;
				justify-content: space-between;
				line-height: 1.313rem;
			}
			
			&__card
			{
				background: var(--ui-benefit-bg);
				border-radius: 6px;
				box-sizing: border-box;
				color: var(--ui-benefit-color);
				flex: 0 1 100%;
				margin-bottom: 15px;
				padding: 35px 15px 35px 100px;
				position: relative;
				text-align: left;
				width: 100%;

				@media @middle-breakpoints
				{
					flex-basis: calc(50% - (25px / 2));
					width: calc(50% - (25px / 2));
				}

				@media @big-breakpoints
				{
					flex-basis: calc(50% - (25px / 2));
					margin-bottom: 25px;
					padding: 40px 40px 40px 180px;
					width: calc(50% - (25px / 2));
				}
				
				&-graphic
				{
					display: block;
					left: 0;
					margin: 0;
					pointer-events: none;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 100px;

					@media @big-breakpoints
					{
						width: 180px;
					}
				}
				
				&-heading
				{
					clear: both;
					display: block;
					font-size: 1.146rem;
					font-weight: 700;
					letter-spacing: 0;
					line-height: 1.250rem;
					margin-bottom: 10px;

					@media @big-breakpoints
					{
						font-size: 1.750rem;
						line-height: 2.125rem;
						margin-bottom: 15px;
					}
					
					.@{referenceUIClassname}
					{
						font-weight: inherit;
					}
				}

				.@{buttonUIClassname}
				{
					display: flex;

					@media @big-breakpoints
					{
						display: inline-flex;
					}
				}
				
				&-description-link
				{
					align-items: center;
					clear: both;
					display: flex;
					justify-content: flex-start;
					vertical-align: middle;
					margin-top: 10px;

					a[href]
					{
						color: inherit;
						display: inline-block;
						font-weight: 500;
						position: relative;
						text-decoration: underline;
						vertical-align: middle;
						white-space: nowrap;

						.material-icons
						{
							left: calc(100%);
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}
			}
		}
		
		.@{documentNamespace}-graphic-text
		{
			clear: both;
			display: block;
			font-size: 3.125rem;
			font-weight: bold;
			letter-spacing: -0.01rem;
			line-height: 3.125rem;
			margin-bottom: 100px;
			margin-top: 120px;
			text-align: left;

			@media @big-breakpoints
			{
				font-size: 4.688rem;
				letter-spacing: 0.025rem;
				line-height: 5.000rem;
				margin-bottom: 140px;
				margin-top: 140px;
			}
			
			mark
			{
				background: transparent;
				color: var(--ui-document-mark-color);
				position: relative;
				transition: color 1s ease;
				
				@media (prefers-reduced-motion)
				{
					transition: none;
				}
				
				&::after
				{
					border-top: var(--ui-document-mark-decoration-color) solid .1em;
					content: '';
					height: 0;
					left: 0;
					position: absolute;
					top: 100%;
					transform: translateY(calc(-100% - 0.025em));
					width: 100%;
					z-index: -1;
				}
			}
			
			.@{referenceUIClassname}
			{
				font-size: 25%;
				transform: translateY(-2.2em);
			}
		}
	}